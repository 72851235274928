import React, { useState } from "react";
import { Outlet } from 'react-router-dom';
import { AppBar, Box, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import SwitchMenu from "../modules/ManagementMenu";
import { SideDrawer } from "./drawer/SideDrawer";
import SearchIcon from '@mui/icons-material/Search';
import { useFooterHeight, useHeaderHeight } from "../../utils/deviceUtils";

const Header: React.FC = () => {
    // 画面幅に応じたメディアクエリ
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // スマホサイズ

    const [isOpened, setOpened] = useState(!isMobile);

    // フィルターメニューの表示/非表示を切り替える関数
    const toggleFilterMenu = () => {
        setOpened(!isOpened);
    };

    // 動的にヘッダーの高さを決定
    const headerHeight = useHeaderHeight();
    const footerHeight = useFooterHeight();

    return (
        <div>
            {/* ヘッダー */}
            <Box sx={{ flexGrow: 1 }}>
                <AppBar
                    position="fixed"
                    sx={{
                        zIndex: theme.zIndex.drawer + 1,
                        height: headerHeight,  // 動的な高さ設定
                        padding: isMobile ? 0 : undefined,  // スマホ時にパディングをリセット
                        margin: isMobile ? 0 : undefined,   // スマホ時にマージンをリセット
                    }}
                >
                    <Toolbar
                        sx={{
                            minHeight: headerHeight, // ツールバーの高さをヘッダーに合わせて動的に設定
                            paddingTop: isMobile ? 0 : undefined,  // スマホ時にパディングをリセット
                            paddingRight: isMobile ? 8 : undefined,  // スマホ時にパディングをリセット
                            margin: isMobile ? 0 : undefined,   // スマホ時にマージンをリセット
                        }}
                    >
                        <SwitchMenu isMobile={isMobile} />
                        <Typography
                            variant="h6"  // タイトルサイズ
                            sx={{
                                flexGrow: 1,
                                textAlign: "center",  // 中央寄せ
                                fontWeight: "bold",  // 太字
                                fontSize: isMobile ? '1rem' : '1.25rem', // スマホ時にフォントサイズを小さく
                            }}
                        >
                            豊島区の保育園探し
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Box>

            {/* サイドバー */}
            <SideDrawer open={isOpened} setOpened={setOpened} />  {/* isMobileとfooterHeightをSideDrawerに渡す */}

            {/* メインコンテンツ */}
            <Box
                component="main"
                sx={{
                    marginTop: headerHeight,  // ヘッダーの高さに合わせて余白を動的に変更
                    marginLeft: isOpened && !isMobile ? '270px' : '0px',  // PCの場合、サイドドロワーを開いたら左マージン
                    paddingTop: 0,
                }}
            >
                <Outlet />
            </Box>

            {/* フッター */}
            {isMobile && (
                <AppBar
                    position="fixed"
                    color="primary"
                    style={{ top: 'auto', bottom: 0, zIndex: 1300 }}  // フッターのz-indexを高くしてSideDrawerより上に表示
                >
                    <Toolbar style={{ display: 'flex', justifyContent: 'center', height: footerHeight }}>
                        {/* フィルターボタン */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',  // アイコンとテキストを縦に並べる
                                alignItems: 'center',     // 横方向に中央揃え
                                justifyContent: 'center', // 縦方向に中央揃え
                            }}
                        >
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={toggleFilterMenu}
                                sx={{
                                    fontSize: 30,
                                    margin:0,
                                    padding: 0, // アイコンのパディングを0にする
                                }}
                            >
                                <SearchIcon sx={{ fontSize: 30 }} /> {/* アイコンのサイズを大きく */}
                            </IconButton>
                            <Typography variant="caption" color="inherit" fontWeight="bold"
                            sx={{
                                padding: 0 
                            }}
                            > {/* テキストを太字に */}
                                検索
                            </Typography>
                        </Box>
                    </Toolbar>
                </AppBar>
            )}
        </div>
    );
}

export default Header;
