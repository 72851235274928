import React from 'react';
import {
    Drawer,
    List,
    Divider,
    ListItem,
    ListItemText,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    Link,
} from '@mui/material';
import { Nursery, updated } from '../../../data/Toshimaku_ Nurseries';
import { formatAge, formatTime_number } from '../../../utils/dataUtils';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useIsMobile } from '../../../utils/deviceUtils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

interface NurseryDrawerProps {
    open: boolean;
    onClose: () => void;
    selected: Nursery | null;
    footerHeight: string; 
    headerHeight: string; 
}

// 別タブで開くためのURLを生成
const generateDetailPageUrl = (nursery: Nursery) => `/nursery/tokyo/toshimaku/nursery-detail/${nursery.id}`;

// 特定の情報をリストとしてレンダリング
const renderListItem = (label: string, value: React.ReactNode, isDivider: boolean) => (
    <>
        <ListItem sx={{ padding: '4px 0px' }}>
            <ListItemText 
                primary={label} 
                secondary={value} 
                primaryTypographyProps={{ sx: { fontWeight: 'bold', fontSize: '1rem' } }}  
                secondaryTypographyProps={{ sx: { fontSize: '1rem' } }}  
            />
        </ListItem>
        {isDivider && <Divider />} {/* isDividerがtrueの場合にDividerを表示 */}
    </>
);


const NurseryDrawer: React.FC<NurseryDrawerProps> = ({ open, onClose, selected, footerHeight, headerHeight }) => {
    const isMobile = useIsMobile();

    // 高さとマージンの計算
    const drawerWidth = isMobile ? '100vw' : 400;  
    const drawerHeight = `calc(100vh - ${footerHeight})`;
    const headerHeightValue = parseInt(headerHeight.replace('px', ''), 10);
    const drawerMarginTop = `${headerHeightValue + 16}px`; 

    return (
        <Drawer 
            anchor="right" 
            open={open} 
            onClose={onClose} 
            sx={{
                '& .MuiDrawer-paper': {
                    width: drawerWidth,  
                    height: drawerHeight,  
                    overflow: 'auto',
                },
            }}
        >
            <div style={{ margin: '16px', marginTop: drawerMarginTop }}>
                {selected ? (
                    <>
                    <Typography 
                        variant="h5" 
                        sx={{ 
                            display: 'flex', 
                            alignItems: 'center',  // 縦中央揃え
                            position: 'sticky',  // スクロール時に固定
                            top: headerHeight,  // 上部からの位置を設定
                            backgroundColor: 'white',  // 背景色を白にする（他の要素が隠れないように）
                            zIndex: 1000,  // 他の要素より前に表示
                            padding: '8px 0',  // 少しの余白
                        }}
                    >
                        <ArrowBackIosIcon onClick={onClose}  fontSize="small"/>  {/* 戻るアイコンと名前の間に余白 */}
                        {selected.name}
                        {!isMobile && (  // モバイルではリンクを非表示
                            <span style={{ fontSize: '0.875rem', color: 'inherit', marginLeft: '8px' }}>
                                <Link 
                                    href={generateDetailPageUrl(selected)} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    color="inherit"
                                >
                                    <OpenInNewIcon fontSize="small" />
                                </Link>
                            </span>
                        )}
                    </Typography>

                    {/* 保育園の基本情報 */}
                    <List>
                        {renderListItem('住所', selected.address, true)}
                        {renderListItem('保育時間', `${formatTime_number(selected.hour.openTime)} 〜 ${formatTime_number(selected.hour.closeTime)}`, true)}
                        {renderListItem('定員', `${selected.capacity}人`, true)}
                        {renderListItem('運営会社', 
                            <>
                                {selected.company}（
                                <Link href={selected.url} target="_blank" rel="noopener noreferrer" color="inherit">
                                    公式HP
                                </Link>
                                ）
                            </>
                        , true)}
                        {renderListItem('開設', selected.established, true)}
                        {renderListItem(
                            '空き状況',
                            <>
                                <TableContainer component={Paper} sx={{ mt:'4px' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ padding: '4px 16px', verticalAlign: 'middle', fontSize: '1rem', fontWeight: 'bold' }}>年齢</TableCell>
                                                <TableCell sx={{ padding: '4px 16px', verticalAlign: 'middle', fontSize: '1rem', fontWeight: 'bold' }}>定員</TableCell>
                                                <TableCell sx={{ padding: '4px 16px', verticalAlign: 'middle', fontSize: '1rem', fontWeight: 'bold' }}>空き</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selected.availability.map((row, index) => (
                                                <TableRow 
                                                    key={index}
                                                    sx={{
                                                        backgroundColor: row.empty === 0 ? '#f0f0f0' : 'inherit' // 空きが0人の場合に背景をグレーに
                                                    }}
                                                >
                                                    <TableCell sx={{ padding: '4px 16px', verticalAlign: 'middle', fontSize: '1rem' }}>{formatAge(row.age)}歳児</TableCell>
                                                    <TableCell sx={{ padding: '4px 16px', verticalAlign: 'middle', fontSize: '1rem' }}>{row.capacity}人</TableCell>
                                                    <TableCell sx={{ padding: '4px 16px', verticalAlign: 'middle',fontSize: '1rem' }}>{row.empty}人</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                {/* 右下に「2024年10月5日時点」を小さく表示 */}
                                <Typography
                                    variant="caption"
                                    sx={{
                                        display: 'block',
                                        textAlign: 'right',  // 右揃え
                                        marginTop: '8px',
                                        fontSize: '0.75rem',  // 小さなフォントサイズ
                                    }}
                                >
                                    <Link href={updated.source} target="_blank" rel="noopener noreferrer" color="inherit">
                                        {updated.updateDate}時点
                                    </Link>
                                </Typography>
                            </>
                        , false)}
                    </List>
                    </>
                ) : (
                    <Typography variant="body1">選択された場所はありません。</Typography>
                )}
            </div>
        </Drawer>
    );
};

export default NurseryDrawer;
