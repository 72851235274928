import React, { createContext, useState, ReactNode } from "react";

// コンテキストの作成
export const FilterContext = createContext<{
  //保育年齢
  ageFilter: number[];
  setAgeFilterValue: React.Dispatch<React.SetStateAction<number[]>>;

  //定員数
  capacityFilter: number[];
  setCapacityFilterValue: React.Dispatch<React.SetStateAction<number[]>>;

  //保育開始時間
  openTimeFilter: number;
  setOpenTimeFilterValue: React.Dispatch<React.SetStateAction<number>>;
  
  //保育終了時間
  closeTimeFilter: number;
  setCloseTimeFilterValue: React.Dispatch<React.SetStateAction<number>>;

  //空き状況
  emptyFilter: number[];
  setEmptyFilterValue: React.Dispatch<React.SetStateAction<number[]>>;

  //カテゴリー
  categoryFilter: number[];
  setCategoryFilterValue: React.Dispatch<React.SetStateAction<number[]>>;

} | undefined>(undefined);

export const SliderProvider = ({ children }: { children: ReactNode }) => {
  const [capacityFilterValue, setCapacityFilterValue] = useState<number[]>([0, 200]);
  const [ageFilterValue, setAgeFilterValue] = useState<number[]>([0, 5]);
  const [openTimeFilterValue, setOpenTimeFilterValue] = useState<number>(9999);
  const [closeTimeFilterValue, setCloseTimeFilterValue] = useState<number>(0);
  const [emptyFilterValue, setEmptyFilterValue] = useState<number[]>([999]);
  const [categoryFilterValue, setCategoryFilterValue] = useState<number[]>([999]);

  return (
    <FilterContext.Provider value={{ 
      capacityFilter: capacityFilterValue, setCapacityFilterValue, 
      ageFilter: ageFilterValue, setAgeFilterValue,
      openTimeFilter: openTimeFilterValue, setOpenTimeFilterValue,
      closeTimeFilter: closeTimeFilterValue, setCloseTimeFilterValue,
      emptyFilter: emptyFilterValue, setEmptyFilterValue,
      categoryFilter: categoryFilterValue, setCategoryFilterValue,
    }}>
      {children}
    </FilterContext.Provider>
  );
};
