export const formatTime_string = (timeStr:string) => {
    // 時間と分に分ける
    const time = timeStr.padStart(4, '0'); // 4桁になるように先頭に0を追加
    const hours = parseInt(time.slice(0, 2), 10); // 最初の2桁が時間
    const minutes = time.slice(2); // 後ろの2桁が分
  
    return `${hours}時${minutes}分`;
 };

 export const formatTime_number = (timeNum: number) => {
    // 数値を4桁の文字列に変換
    const timeStr = timeNum.toString().padStart(4, '0');
    
    // 時間と分に分ける
    const hours = parseInt(timeStr.slice(0, 2), 10); // 最初の2桁が時間
    const minutes = timeStr.slice(2); // 後ろの2桁が分
  
    return `${hours}時${minutes}分`;
};


 export const formatAge =(target_age:number) => {
    return target_age === 5 ? '4・5' : target_age;
 };

 // カテゴリーをテキストに変換する関数
 export const getCategoryLabel = (category: number): { label: string; color: string } => {
   switch (category) {
       case 0:
           return { label: '認可', color: '#1976d2' }; // 青色
       case 1:
           return { label: '認証', color: '#ff6f91' }; // ピンク色
       default:
           return { label: 'その他', color: '#9e9e9e' }; // グレー
   }
};