import { useContext } from "react";
import { Slider, Typography, Box } from "@mui/material";
import { FilterContext } from "../../../context/FilterContext";

const AgeFilter = () => {
  const sliderContext = useContext(FilterContext);
  if (!sliderContext) return null;

  const { ageFilter: value, setAgeFilterValue } = sliderContext;

  const handleChange = (event: Event, newValue: number | number[]) => {
    setAgeFilterValue(newValue as number[]);
  };

  // 選択された範囲を返す関数
  const getSelectedRange = (value: number[]) => {
    const lowerBound = value[0] + "歳";
    const upperBound = value[1] + "歳";
    return `${lowerBound} 〜 ${upperBound}`;
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* タイトル */}
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold'}} gutterBottom>
        保育年齢
      </Typography>

      <Box sx={{ px: 2 }}> {/* 内側余白を一括指定 */}
        <Slider
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          step={1}
          min={0}
          max={5}
          sx={{ mt: 0, mb: 0 }}
        />

        {/* 選択範囲の表示 */}
        <Typography variant="body2" align="center" sx={{ mb: 2 }}>
          {getSelectedRange(value)}
        </Typography>
      </Box>
    </Box>
  );
};

export default AgeFilter;
