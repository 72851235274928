// src/App.tsx

import React from 'react';
import { AppRoutes } from './AppRoutes';
import { SliderProvider } from './context/FilterContext';
import { LoadScript } from '@react-google-maps/api';

const MAP_API_KEY = 'AIzaSyAwtpS-Zm6lHUkurw00JbvE4-CeO-RzACE'; // APIキー

const App: React.FC = () => {
  
  return (
    <LoadScript googleMapsApiKey={MAP_API_KEY}>
      <SliderProvider>
        {/* ルーティング */}
        <AppRoutes />
      </SliderProvider>
    </LoadScript>
  );
}

export default App;
