import { useMediaQuery, useTheme } from '@mui/material';

// isMobile を判定するカスタムフック
export const useIsMobile = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down('sm'));
};

// footerHeight を取得するカスタムフック
export const useHeaderHeight = () => {
    const isMobile = useIsMobile();  // カスタムフックを使ってスマホ判定
    return isMobile ? '40px' : '64px';  // スマホなら56、それ以外は0
};

// footerHeight を取得するカスタムフック
export const useFooterHeight = () => {
    const isMobile = useIsMobile();  // カスタムフックを使ってスマホ判定
    return isMobile ? '56px' : '0px';  // スマホなら56、それ以外は0
};