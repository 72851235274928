import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { FilterMenu } from '../../modules/FilterMenu';
import { useFooterHeight, useHeaderHeight, useIsMobile } from '../../../utils/deviceUtils';

type Props = {
    open?: boolean;
    setOpened: (open: boolean) => void;  // SideDrawer の開閉状態を管理する関数
    
};

export const SideDrawer: React.FC<Props> = ({ open = false, setOpened}) => {

    const isMobile = useIsMobile();  // isMobileを受け取る
    const footerHeight = useFooterHeight();  // フッターの高さを受け取る
    const headerHeight = useHeaderHeight();

    const handleClose = () => {
        setOpened(false);  // ドロワーを閉じる
    };

    return (
        <Drawer
            variant={isMobile ? "temporary" : "persistent"}  // スマホ時には一時的なオーバーレイ表示、PCでは固定表示
            open={open}
            onClose={handleClose}  // グレー背景をクリックするとドロワーを閉じる
            sx={{
                display: { xs: 'block', md: 'block' },
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: 270,
                    height: isMobile ? `calc(100vh - ${footerHeight})` : '100vh', // footerHeightをpx単位に変換
                    zIndex: 1200,  // フッターよりも下に設定
                    position: 'fixed',  // フッターに被らないように固定
                    bottom: 0,  // フッターの上に余白を確保
                },
            }}
            id="sidebar"
        >
            <Box sx={{ overflow: 'auto'}} id="sidebar_box">
                <List id="sidebar_list" sx={{marginTop: headerHeight}}>
                    <FilterMenu isMobile={isMobile} />
                </List>
            </Box>
        </Drawer>
    );
};
