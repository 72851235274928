import React from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';

interface GoogleMapComponentProps {
  latitude: number;
  longitude: number;
}

const containerStyle = {
  width: '100%',
  height: '400px'
};

const GoogleMapComponent: React.FC<GoogleMapComponentProps> = ({ latitude, longitude }) => {
  const center = {
    lat: latitude,
    lng: longitude
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={16} // ズームレベルを適切に調整
      options={{
      mapTypeControl: false, // 地図/航空写真切り替えボタンを非表示にする
      fullscreenControl: false,   // 全画面切り替えボタンを非表示にする
      }}
    >
      {/* マーカーをここで追加 */}
      <MarkerF position={center} />
    </GoogleMap>
  );
};

export default GoogleMapComponent;
