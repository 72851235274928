import * as React from 'react';
import { InputLabel, MenuItem, Select, FormControl, SelectChangeEvent, Typography } from '@mui/material';
import { FilterContext } from '../../../context/FilterContext';

export default function CategoryFilter() {
  const sliderContext = React.useContext(FilterContext);
  if (!sliderContext) return null;

  const { categoryFilter: categoryFilterValue, setCategoryFilterValue } = sliderContext;

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;

    let newEmptyFilterValues = typeof value === 'string' ? value.split(',').map(Number) : value;
    const uniqueValue = newEmptyFilterValues.filter(element => !categoryFilterValue.includes(element));

    if(uniqueValue.includes(999)){
      newEmptyFilterValues = [999];
    }else if(categoryFilterValue.includes(999)){
      newEmptyFilterValues = newEmptyFilterValues.slice(1);
    }

    if(newEmptyFilterValues.length === 0){
      newEmptyFilterValues = [999];
    }

    setCategoryFilterValue(newEmptyFilterValues);
  };

  return (
    <div>
      <Typography id="range-slider" variant="subtitle1" sx={{ fontWeight: 'bold',mt: -1 }} gutterBottom>
        認可 / 認証 / その他
        <span style={{ fontSize: '0.875rem', color: 'inherit', fontWeight: 'normal' }}> (複数可)</span>
      </Typography>
      <FormControl 
        sx={{ 
          mt: 1,
          mb: 2,
          marginLeft: 0,
          marginRight: 0,
          minWidth: 240,
        }}
      >
        <InputLabel id="demo-select-small-label">カテゴリー</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          multiple
          value={categoryFilterValue} // number[]をそのまま使用
          label="カテゴリー"
          onChange={handleChange}
          sx={{ height: '36px', lineHeight: '36px' }} // 高さを調整
        >
          <MenuItem value={999}>指定なし</MenuItem>
          <MenuItem value={0}>認可</MenuItem>
          <MenuItem value={1}>認証(近日リリース予定)</MenuItem>
          <MenuItem value={2}>その他</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}