import AgeFilter from './filter/AgeFilter';
import CapatiryFilter from './filter/CapatiryFilter';
import EmptyFilter from './filter/EmptyFilter';
import HourFilter from './filter/HourFilter';
import CategoryFilter from './filter/CategoryFilter';
import { Button, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

type FilterMenuProps = {
    isMobile: boolean;
};

export const FilterMenu: React.FC<FilterMenuProps> = ({ isMobile }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 2,
                height: '100%',  // 高さ100%で子要素を中央に配置
            }}
        >
            <CategoryFilter/>
            <CapatiryFilter/>
            <AgeFilter />
            <EmptyFilter />
            <HourFilter />
            {!isMobile && (
                <Button variant="contained" startIcon={<SearchIcon />} sx={{ fontWeight: 'bold', width: '100%' }}>
                    この条件で検索
                </Button>
            )}
        </Box>
    );
}
