import * as React from 'react';
import { InputLabel, MenuItem, Select, FormControl, SelectChangeEvent, Typography, Box } from '@mui/material';
import { FilterContext } from '../../../context/FilterContext';
import { formatAge } from '../../../utils/dataUtils';

export default function EmptyFilter() {
  const sliderContext = React.useContext(FilterContext);
  if (!sliderContext) return null;

  const { emptyFilter: emptyFilterValues, setEmptyFilterValue: setEmptyFilterValues } = sliderContext;
  const NO_SELECTION = 999; // "指定なし" の値を定義

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;

    // 選択された値を処理
    let newEmptyFilterValues = typeof value === 'string' ? value.split(',').map(Number) : value;
    const uniqueValue = newEmptyFilterValues.filter(element => !emptyFilterValues.includes(element));

    if (uniqueValue.includes(NO_SELECTION)) {
      newEmptyFilterValues = [NO_SELECTION];
    } else if (emptyFilterValues.includes(NO_SELECTION)) {
      newEmptyFilterValues = newEmptyFilterValues.slice(1);
    }

    if (newEmptyFilterValues.length === 0) {
      newEmptyFilterValues = [NO_SELECTION];
    }

    setEmptyFilterValues(newEmptyFilterValues);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }} gutterBottom>
        空き枠有無
        <span style={{ fontSize: '0.875rem', color: 'inherit', fontWeight: 'normal' }}> (複数可)</span>
      </Typography>
      <FormControl sx={{ mt: 1, mb: 2, width: '100%' }}>
        <InputLabel>対象年齢</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          multiple
          value={emptyFilterValues}
          label="対象年齢"
          onChange={handleChange}
          sx={{ height: '36px', lineHeight: '36px' }}
        >
          <MenuItem value={NO_SELECTION}>指定なし</MenuItem>
          {[0, 1, 2, 3, 5].map(age => (
            <MenuItem key={age} value={age}>
              {formatAge(age)}歳
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
