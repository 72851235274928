import * as React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemButton,
  Box
} from '@mui/material';
import { useContext, useState } from 'react';
import { FilterContext } from '../context/FilterContext';
import Nurseries, { Nursery } from '../data/Toshimaku_ Nurseries';
import { formatTime_number, getCategoryLabel } from '../utils/dataUtils';
import NurseryDrawer from '../components/layout/drawer/NurseryDrawer';
import { useFooterHeight, useHeaderHeight } from '../utils/deviceUtils';

// NurseryListItemコンポーネントを作成してリストアイテムの描画を分割
const NurseryListItem = ({
  nursery,
  selectedIndex,
  index,
  handleListItemClick,
}: {
  nursery: Nursery;
  selectedIndex: number;
  index: number;
  handleListItemClick: (event: React.MouseEvent<HTMLDivElement>, index: number) => void;
}) => {
  const { label, color } = getCategoryLabel(nursery.category);

  return (
    <ListItemButton
      key={index}
      selected={selectedIndex === index}
      onClick={(event) => handleListItemClick(event, index)}
    >
      <ListItem>
        <ListItemText
          primary={
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  backgroundColor: color,
                  color: 'white',
                  padding: '2px 8px',
                  borderRadius: '12px',
                  fontSize: '0.875rem',
                }}
              >
                {label}
              </Box>
              <Typography variant="body1" fontWeight="bold" sx={{ marginLeft: '8px' }}>
                {nursery.name}
              </Typography>
            </Box>
          }
          secondary={
            <Box sx={{ marginTop: '8px' }}>
              <Typography
                component="span"
                variant="body2"
                sx={{ color: 'text.primary', display: 'inline' }}
              >
                {nursery.address}
                <br />
                保育年齢：{nursery.age.ageFrom}〜{nursery.age.ageTo}歳
                <br />
                定員：{nursery.capacity}人
                <br />
                保育時間：{formatTime_number(nursery.hour.openTime)}〜
                {formatTime_number(nursery.hour.closeTime)}
              </Typography>
            </Box>
          }
        />
      </ListItem>
    </ListItemButton>
  );
};

// フィルタリング処理を分離
const filterNurseries = (nurseries: Nursery[], filters: any) => {
  const {
    capacity_min,
    capacity_max,
    age_min,
    age_max,
    openTime,
    closeTime,
    emptyFilterValues,
    categoryFilterValues,
    isEmpty,
  } = filters;

  return nurseries.filter(
    (nursery) =>
      nursery.capacity >= capacity_min &&
      nursery.capacity <= capacity_max &&
      nursery.age.ageFrom <= age_min &&
      nursery.age.ageTo >= age_max &&
      nursery.hour.openTime <= openTime &&
      nursery.hour.closeTime >= closeTime &&
      isEmpty(nursery, emptyFilterValues) &&
      categoryFilterValues.includes(nursery.category)
  );
};

// メインコンポーネント
const FilteredView = () => {
  const sliderContext = useContext(FilterContext);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selected, setSelected] = useState<Nursery | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const headerHeight = useHeaderHeight();
  const footerHeight = useFooterHeight();

  if (!sliderContext) return null;

  const {
    capacityFilter,
    ageFilter,
    openTimeFilter,
    closeTimeFilter,
    emptyFilter,
    categoryFilter,
  } = sliderContext;

  const filters = {
    capacity_min: capacityFilter[0] === 0 ? -1 : capacityFilter[0],
    capacity_max: capacityFilter[1] === 200 ? 999 : capacityFilter[1],
    age_min: ageFilter[0],
    age_max: ageFilter[1],
    openTime: openTimeFilter,
    closeTime: closeTimeFilter,
    emptyFilterValues: emptyFilter[0] === 999 ? [] : emptyFilter,
    categoryFilterValues: categoryFilter[0] === 999 ? [0, 1, 2] : categoryFilter,
    isEmpty: (nursery: Nursery, emptyFilterValues: number[]) => {
      const emptyAges = nursery.availability
        .filter((element) => element.empty !== 0)
        .flatMap((element) => element.age);
      return emptyFilterValues.every((element) => emptyAges.includes(element));
    },
  };

  console.log('before_Nurseries:', Nurseries);

  const data = filterNurseries(Nurseries, filters);

  console.log('after_data:', data);


  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    setSelectedIndex(index);
    setSelected(data[index]);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setSelectedIndex(-1);
    setSelected(null);
    setDrawerOpen(false);
  };

  return (
    <>
      {/* フィルター結果が0件の場合のメッセージ表示 */}
      {data.length === 0 ? (
        <Typography variant="h6" align="center" sx={{ marginTop: '100px' }}>
          検索条件に一致する保育園はありません
        </Typography>
      ) : (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {data.map((nursery, i) => (
            <NurseryListItem
              key={i}
              nursery={nursery}
              selectedIndex={selectedIndex}
              index={i}
              handleListItemClick={handleListItemClick}
            />
          ))}
        </List>
      )}

      <NurseryDrawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        selected={selected}
        headerHeight={headerHeight}
        footerHeight={footerHeight}
      />
    </>
  );
};

const View = () => {
  return <FilteredView />;
};

export default View;
