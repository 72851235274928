import { useContext } from "react";
import { Slider, Typography, Box } from "@mui/material";
import { FilterContext } from "../../../context/FilterContext";

const CapatiryFilter = () => {
  const sliderContext = useContext(FilterContext);
  if (!sliderContext) return null;

  const { capacityFilter: value, setCapacityFilterValue } = sliderContext;

  const handleChange = (event: Event, newValue: number | number[]) => {
    setCapacityFilterValue(newValue as number[]);
  };

  // 選択された範囲を返す関数
  const getSelectedRange = (value: number[]) => {
    const lowerBound = value[0] === 0 ? "下限なし" : `${value[0]}人`;
    const upperBound = value[1] === 200 ? "上限なし" : `${value[1]}人`;
    return `${lowerBound} 〜 ${upperBound}`;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }} gutterBottom>
        園児の定員数
      </Typography>

      <Box sx={{ px: 2 }}>
        <Slider
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          step={10}
          min={0}
          max={200}
          sx={{ height: 4 }}
        />

        <Typography variant="body2" align="center" sx={{ mt: 0, mb: 2  }}>
          {getSelectedRange(value)}
        </Typography>
      </Box>
    </Box>
  );
};

export default CapatiryFilter;
