import { useContext } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { FilterContext } from "../../../context/FilterContext";

const times = [600, 630, 700, 730, 800, 1830, 1900, 1930, 2000, 2030, 2100]; // 時間の値をまとめる

const HourFilter = () => {
  const sliderContext = useContext(FilterContext);
  if (!sliderContext) return null;

  const { openTimeFilter: openTimeValue, setOpenTimeFilterValue, closeTimeFilter: closeTimeValue, setCloseTimeFilterValue } = sliderContext;

  // 共通の変更ハンドラを使う
  const handleChange = (setter: React.Dispatch<React.SetStateAction<number>>) => (event: SelectChangeEvent<string>) => {
    setter(parseInt(event.target.value, 10)); // stringからnumberに変換
  };

  return (
    <Box>
      <Typography id="range-slider" variant="subtitle1" sx={{ fontWeight: 'bold' }} gutterBottom>
        保育時間
      </Typography>
      <Box sx={{ display: 'flex', gap: 1 }}> {/* フレックスレイアウト */}
        {/* 開始時間 */}
        <FormControl sx={{ mt: 1, mb: 2}} size="small">
          <InputLabel>開始時間</InputLabel>
          <Select
            value={openTimeValue.toString()} // 数値を文字列に変換してセット
            label="保育開始時間"
            onChange={handleChange(setOpenTimeFilterValue)} // 共通ハンドラで処理
            sx={{ height: '36px', lineHeight: '36px', minWidth: 114.5 }}
          >
            <MenuItem value={9999}>指定なし</MenuItem>
            {times.filter(time => time <= 800).map(time => (
              <MenuItem key={time} value={time}>
                {`${Math.floor(time / 100)}:${time % 100 === 0 ? '00' : '30'}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* 終了時間 */}
        <FormControl sx={{ mt: 1, mb: 2}} size="small">
          <InputLabel>終了時間</InputLabel>
          <Select
            value={closeTimeValue.toString()} // 数値を文字列に変換してセット
            label="保育終了時間"
            onChange={handleChange(setCloseTimeFilterValue)} // 共通ハンドラで処理
            sx={{ height: '36px', lineHeight: '36px', minWidth: 114.5  }}
          >
            <MenuItem value={0}>指定なし</MenuItem>
            {times.filter(time => time >= 1830).map(time => (
              <MenuItem key={time} value={time}>
                {`${Math.floor(time / 100)}:${time % 100 === 0 ? '00' : '30'}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default HourFilter;
