import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import TagManager from 'react-gtm-module';

// タグマネージャーの初期化
const tagManagerArgs = {
  gtmId: 'GTM-WCFX62JG' // ここにコンテナIDを設定
};
TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root');
if(container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
}

reportWebVitals();