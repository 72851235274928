import React from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, AppBar, Toolbar, ListItem, ListItemText, Divider, List, Link, Box } from '@mui/material';
import { formatAge, formatTime_number } from '../utils/dataUtils';
import Nurseries, { updated } from '../data/Toshimaku_ Nurseries'; 
import GoogleMapComponent from '../components/map/GoogleMapComponent';

// 特定の情報をリストとしてレンダリング
const renderListItem = (label: string, value: React.ReactNode, isDivider: boolean) => (
    <>
        <ListItem sx={{ padding: '4px 0px' }}>
            <ListItemText 
                primary={label} 
                secondary={value} 
                primaryTypographyProps={{ sx: { fontWeight: 'bold', fontSize: '1rem' } }}  
                secondaryTypographyProps={{ sx: { fontSize: '1rem' } }}  
            />
        </ListItem>
        {isDivider && <Divider />} {/* isDividerがtrueの場合にDividerを表示 */}
    </>
);

const NurseryDetailPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    if (!id) {
        return <div>保育園IDが指定されていません。</div>;
    }

    const nursery = Nurseries.find(nursery => nursery.id === id);

    if (!nursery) {
        return <div>該当する保育園が見つかりません。</div>;
    }

    return (
        <div style={{ backgroundColor: '#f0f0f0', minHeight: '100vh', padding: '0 0 20px 0' }}> {/* 背景をグレーに */}
            <AppBar position="static" sx={{ width: '100%' }}>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <Typography variant="h6" component="div">
                        保育園詳細
                    </Typography>
                    <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: "bold",  // 太字
                            }}
                        >
                            豊島区の保育園探し
                        </Typography>
                    </Box>
                    <Button color="inherit" component={RouterLink} to="/nursery/tokyo/toshimaku/map">
                        トップに戻る
                    </Button>
                </Toolbar>
            </AppBar>

            {/* コンテンツ部分 */}
            <div style={{ maxWidth: '800px', margin: '20px auto', backgroundColor: 'white', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Typography variant="h4">{nursery.name}</Typography>
                <List>
                    {renderListItem('住所', nursery.address, true)}
                    {renderListItem('保育時間', `${formatTime_number(nursery.hour.openTime)} 〜 ${formatTime_number(nursery.hour.closeTime)}`, true)}
                    {renderListItem('定員', `${nursery.capacity}人`, true)}
                    {renderListItem('運営会社', 
                        <>
                            {nursery.company}（
                            <Link href={nursery.url} target="_blank" rel="noopener noreferrer" color="inherit">
                                公式HP
                            </Link>
                            ）
                        </>
                    , true)}
                    {renderListItem('開設', nursery.established, true)}
                    {renderListItem('電話番号', nursery.tel, true)}
                    {renderListItem(
                        '空き状況',
                        <>
                            <TableContainer component={Paper} sx={{ mt:'4px' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ padding: '4px 16px', verticalAlign: 'middle', fontSize: '1rem', fontWeight: 'bold' }}>年齢</TableCell>
                                            <TableCell sx={{ padding: '4px 16px', verticalAlign: 'middle', fontSize: '1rem', fontWeight: 'bold' }}>定員</TableCell>
                                            <TableCell sx={{ padding: '4px 16px', verticalAlign: 'middle', fontSize: '1rem', fontWeight: 'bold' }}>空き</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {nursery.availability.map((row, index) => (
                                            <TableRow 
                                                key={index}
                                                sx={{
                                                    backgroundColor: row.empty === 0 ? '#f0f0f0' : 'inherit' // 空きが0人の場合に背景をグレーに
                                                }}
                                            >
                                                <TableCell sx={{ padding: '4px 16px', verticalAlign: 'middle', fontSize: '1rem' }}>{formatAge(row.age)}歳児</TableCell>
                                                <TableCell sx={{ padding: '4px 16px', verticalAlign: 'middle', fontSize: '1rem' }}>{row.capacity}人</TableCell>
                                                <TableCell sx={{ padding: '4px 16px', verticalAlign: 'middle',fontSize: '1rem' }}>{row.empty}人</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {/* 右下に「2024年10月5日時点」を小さく表示 */}
                            <Typography
                                variant="caption"
                                sx={{
                                    display: 'block',
                                    textAlign: 'right',  // 右揃え
                                    marginTop: '8px',
                                    fontSize: '0.75rem',  // 小さなフォントサイズ
                                }}
                            >
                                <Link href={updated.source} target="_blank" rel="noopener noreferrer" color="inherit">
                                    {updated.updateDate}時点
                                </Link>
                            </Typography>
                        </>
                    , false)}
                </List>

                {/* 地図情報 */}
                <Typography variant="h5" style={{ marginTop: '16px', marginBottom: '8px' }}>地図情報</Typography>
                <GoogleMapComponent latitude={nursery.position.lat} longitude={nursery.position.lng} />
            </div>
        </div>
    );
};

export default NurseryDetailPage;
