import { useState, useEffect } from "react";
import { Button, ButtonGroup } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import ListIcon from '@mui/icons-material/List';
import { useNavigate, useLocation } from "react-router-dom";

interface SwitchMenuProps {
  isMobile: boolean;  // 親コンポーネントから渡されるプロパティ
}

const SwitchMenu: React.FC<SwitchMenuProps> = ({ isMobile }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [index, setIndex] = useState<number | null>(null);

  useEffect(() => {
    // URLパスに基づいて初期のインデックスを設定
    if (location.pathname === '/nursery/tokyo/toshimaku/map') {
      setIndex(0);
    } else if (location.pathname === '/nursery/tokyo/toshimaku/view') {
      setIndex(1);
    } else {
      setIndex(0); // デフォルトで地図を選択
    }
  }, [location.pathname]);

  const fontColor = (button_index: number) => {
    return button_index === index ? '#1976d2' : 'white';
  };

  const backColor = (button_index: number) => {
    return button_index === index ? 'white' : '#1976d2';
  };

  const switchPage = (newIndex: number) => {
    setIndex(newIndex);
    if (newIndex === 0) {
      navigate('/nursery/tokyo/toshimaku/map');
    } else if (newIndex === 1) {
      navigate('/nursery/tokyo/toshimaku/view');
    } else {
      navigate('/nursery/tokyo/toshimaku/map');
    }
  };

  // indexがnullの場合は何もレンダリングしない
  if (index === null) {
    return null; // またはローディングインジケータを表示する場合はここに書く
  }

  // スマホの場合のボタンの高さとpaddingを設定
  const buttonHeight = isMobile ? '32px' : '48px'; // スマホは32px、高解像度は48px

  return (
    <ButtonGroup
      variant="outlined"
      aria-label="Basic button group"
      
    >
      <Button
        startIcon={!isMobile ? <PlaceIcon /> : undefined}  // isMobile が true のときは非表示
        style={{ backgroundColor: backColor(0), color: fontColor(0), height: buttonHeight}} 
        onClick={() => switchPage(0)}
      >
        地図
      </Button>
      <Button 
        startIcon={!isMobile ? <ListIcon /> : undefined}  // isMobile が true のときは非表示
        style={{ backgroundColor: backColor(1), color: fontColor(1), height: buttonHeight}} 
        onClick={() => switchPage(1)}
      >
        一覧
      </Button>
    </ButtonGroup>
  );
}

export default SwitchMenu;
