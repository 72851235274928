import React, { useContext, useState } from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import Nurseries, { Nursery } from '../../data/Toshimaku_ Nurseries';
import { FilterContext } from '../../context/FilterContext';
import NurseryDrawer from '../layout/drawer/NurseryDrawer';
import { useFooterHeight, useHeaderHeight, useIsMobile } from '../../utils/deviceUtils';

// 豊島区役所の位置を地図の中心に設定
const center = {
  lat: 35.7333,  // 豊島区役所の緯度
  lng: 139.7114, // 豊島区役所の経度
};

// 保育園のフィルター処理関数
const filterNurseries = (nurseries: Nursery[], filters: any): Nursery[] => {
  const {
    capacity_min,
    capacity_max,
    age_min,
    age_max,
    openTime,
    closeTime,
    emptyFilterValues,
    categoryFilterValues,
    isEmpty,
  } = filters;

  return nurseries.filter((element) =>
    element.capacity >= capacity_min &&
    element.capacity <= capacity_max &&
    element.age.ageFrom <= age_min &&
    element.age.ageTo >= age_max &&
    element.hour.openTime <= openTime &&
    element.hour.closeTime >= closeTime &&
    isEmpty(element, emptyFilterValues) &&
    categoryFilterValues.includes(element.category)
  );
};

// フィルター関連の値を取得するカスタムフック
const useNurseryFilters = () => {
  const sliderContext = useContext(FilterContext);
  if (!sliderContext) return null;

  const { capacityFilter, ageFilter, openTimeFilter, closeTimeFilter, emptyFilter, categoryFilter } = sliderContext;

  const capacity_min = capacityFilter[0] === 0 ? -1 : capacityFilter[0];
  const capacity_max = capacityFilter[1] === 200 ? 999 : capacityFilter[1];
  const age_min = ageFilter[0];
  const age_max = ageFilter[1];
  const openTime = openTimeFilter;
  const closeTime = closeTimeFilter;
  const emptyFilterValues = emptyFilter[0] === 999 ? [] : emptyFilter;
  const categoryFilterValues = categoryFilter[0] === 999 ? [0, 1, 2] : categoryFilter;

  const isEmpty = (nursery: Nursery, emptyFilterValues: number[]): boolean => {
    const emptyAges = nursery.availability.filter(element => element.empty !== 0).flatMap(element => element.age);
    return emptyFilterValues.every(element => emptyAges.includes(element));
  };

  return {
    capacity_min,
    capacity_max,
    age_min,
    age_max,
    openTime,
    closeTime,
    emptyFilterValues,
    categoryFilterValues,
    isEmpty,
  };
};

const GMap: React.FC = () => {
  const isMobile = useIsMobile();
  const headerHeight = useHeaderHeight();
  const footerHeight = useFooterHeight();
  const filters = useNurseryFilters();
  
  // 地図のコンテナスタイル
  const containerStyle = {
    width: '100%',
    height: `calc(100vh - ${headerHeight} - ${footerHeight})`, 
  };
  
  const [selected, setSelected] = useState<Nursery | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false); // Drawerの開閉状態

  if (!filters) return null;  // フィルターデータが存在しない場合に早期リターン

  const data = filterNurseries(Nurseries, filters);

  const handleMarkerClick = (location: Nursery) => {
    setSelected(location);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setSelected(null);
    setDrawerOpen(false);
  };

  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14} // ズームレベルを適切に調整
        options={{
          mapTypeControl: false, // 地図/航空写真切り替えボタンを非表示にする
          fullscreenControl: false,   // 全画面切り替えボタンを非表示にする
          streetViewControl: !isMobile,  // スマホではストリートビューを非表示
        }}
      >
        {data.map(nursery => (
          <MarkerF
            key={nursery.id}
            position={{ lat: nursery.position.lat, lng: nursery.position.lng }}
            onClick={() => handleMarkerClick(nursery)} // マーカーをクリックしたときに選択
          />
        ))}
      </GoogleMap>

      <NurseryDrawer 
        open={drawerOpen} 
        onClose={handleDrawerClose} 
        selected={selected} 
        headerHeight={headerHeight}
        footerHeight={footerHeight} 
      />
    </>
  );
};

export default GMap;
