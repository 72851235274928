// AppRoutes.tsx
import { Routes, Route } from 'react-router-dom';
import Header from './components/layout/Header';
import Map from './pages/Map';
import View from './pages/View';
import NurseryDetailPage from './pages/NurseryDetailPage';

export const AppRoutes = () => {
    return (
        <Routes>
            <Route element={<Header />} >
                {/* <Route path="/nursery/tokyo/toshimaku/" element={<Top />} /> */}
                <Route path="/nursery/tokyo/toshimaku/map" element={<Map />} />
                <Route path="/nursery/tokyo/toshimaku/view" element={<View />} />
            </Route>

            <Route path="/nursery/tokyo/toshimaku/nursery-detail/:id" element={<NurseryDetailPage />} />
        </Routes>
    );
};

export default AppRoutes;
