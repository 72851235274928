export const updated = {
    updateDate:'2024年10月5日',
    source:'https://www.city.toshima.lg.jp/260/kosodate/kosodate/hoikuen/nyuen/documents/0610ketuin.pdf'
  };
  
export type Nursery = {
    id: string
    name: string
    position: { lat: number, lng: number }
    address: string
    tel: string
    company: string
    established: string
    url:string
    hour: { openTime:number, closeTime:number }
    capacity: number

    age:{ ageFrom:number, ageTo:number }
    availability: {age:number, capacity:number, empty:number}[]
    category:number
}

const Nurseries: Nursery[] = 
[
  {id:'1',name:'駒込第一保育園',position: {lat:35.7409377,lng:139.7388975},address:'東京都豊島区駒込7-7-22',tel:'03-3917-0644',company:'',established:'1967年6月1日',url:'',hour:{openTime:715,closeTime:1915},capacity:108,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:12,empty:3},{ age:1,capacity:18,empty:0},{ age:2,capacity:19,empty:3},{ age:3,capacity:19,empty:2},{ age:5,capacity:40,empty:5}],category:0,},
  {id:'2',name:'巣鴨第一保育園',position: {lat:35.7356148,lng:139.733847},address:'東京都豊島区巣鴨3-15-20',tel:'03-3910-8900',company:'',established:'1964年9月1日',url:'',hour:{openTime:715,closeTime:1915},capacity:106,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:12,empty:0},{ age:1,capacity:18,empty:1},{ age:2,capacity:18,empty:0},{ age:3,capacity:18,empty:0},{ age:5,capacity:40,empty:5}],category:0,},
  {id:'3',name:'西巣鴨第三保育園',position: {lat:35.7363766,lng:139.7265117},address:'東京都豊島区西巣鴨1-2-14',tel:'03-3940-2341',company:'',established:'1979年6月1日',url:'',hour:{openTime:715,closeTime:1815},capacity:114,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:12,empty:0},{ age:1,capacity:18,empty:0},{ age:2,capacity:18,empty:0},{ age:3,capacity:18,empty:0},{ age:5,capacity:48,empty:4}],category:0,},
  {id:'4',name:'東池袋第二保育園',position: {lat:35.7324882,lng:139.722823},address:'東京都豊島区東池袋2-34-1',tel:'03-3988-3570',company:'',established:'1977年10月1日',url:'',hour:{openTime:715,closeTime:1815},capacity:101,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:10,empty:0},{ age:1,capacity:15,empty:1},{ age:2,capacity:18,empty:0},{ age:3,capacity:18,empty:0},{ age:5,capacity:40,empty:2}],category:0,},
  {id:'5',name:'西池袋第二保育園',position: {lat:35.7292293,lng:139.6988943},address:'東京都豊島区西池袋4-22-18',tel:'03-3957-7521',company:'',established:'1972年10月1日',url:'',hour:{openTime:715,closeTime:1815},capacity:97,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:8,empty:0},{ age:1,capacity:15,empty:0},{ age:2,capacity:17,empty:0},{ age:3,capacity:17,empty:0},{ age:5,capacity:40,empty:9}],category:0,},
  {id:'6',name:'池袋第一保育園',position: {lat:35.7391271,lng:139.7207231},address:'東京都豊島区上池袋3-39-11',tel:'03-3916-8568',company:'',established:'1961年4月1日',url:'',hour:{openTime:715,closeTime:1915},capacity:111,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:12,empty:0},{ age:1,capacity:16,empty:2},{ age:2,capacity:19,empty:1},{ age:3,capacity:19,empty:2},{ age:5,capacity:45,empty:12}],category:0,},
  {id:'7',name:'池袋第二保育園',position: {lat:35.7423985,lng:139.7123463},address:'東京都豊島区池袋本町3-4-5',tel:'03-3987-4648',company:'',established:'1966年6月1日',url:'',hour:{openTime:715,closeTime:1815},capacity:101,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:9,empty:5},{ age:1,capacity:14,empty:2},{ age:2,capacity:17,empty:2},{ age:3,capacity:17,empty:3},{ age:5,capacity:44,empty:11}],category:0,},
  {id:'8',name:'池袋第五保育園',position: {lat:35.7342891,lng:139.7052073},address:'東京都豊島区池袋3-26-22',tel:'03-3987-4653',company:'',established:'1973年5月1日',url:'',hour:{openTime:715,closeTime:1815},capacity:113,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:13,empty:0},{ age:1,capacity:17,empty:0},{ age:2,capacity:20,empty:3},{ age:3,capacity:20,empty:0},{ age:5,capacity:43,empty:3}],category:0,},
  {id:'9',name:'高南保育園',position: {lat:35.7164517,lng:139.7141539},address:'東京都豊島区高田2-12-7',tel:'03-3987-6845',company:'',established:'1965年12月15日',url:'',hour:{openTime:715,closeTime:1815},capacity:92,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:9,empty:0},{ age:1,capacity:13,empty:0},{ age:2,capacity:16,empty:0},{ age:3,capacity:16,empty:0},{ age:5,capacity:38,empty:2}],category:0,},
  {id:'10',name:'目白第一保育園',position: {lat:35.7237725,lng:139.6957348},address:'東京都豊島区目白5-18-2',tel:'03-3953-2293',company:'',established:'1975年12月1日',url:'',hour:{openTime:715,closeTime:1815},capacity:101,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:9,empty:0},{ age:1,capacity:16,empty:0},{ age:2,capacity:19,empty:0},{ age:3,capacity:19,empty:0},{ age:5,capacity:38,empty:0}],category:0,},
  {id:'11',name:'目白第二保育園',position: {lat:35.7230367,lng:139.7094768},address:'東京都豊島区目白2-23-9',tel:'03-3986-6261',company:'',established:'1980年10月1日',url:'',hour:{openTime:715,closeTime:1815},capacity:102,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:12,empty:0},{ age:1,capacity:17,empty:0},{ age:2,capacity:17,empty:0},{ age:3,capacity:17,empty:0},{ age:5,capacity:39,empty:5}],category:0,},
  {id:'12',name:'南長崎第一保育園',position: {lat:35.730053,lng:139.6818104},address:'東京都豊島区南長崎5-23-7',tel:'03-3952-6375',company:'',established:'1974年12月1日',url:'',hour:{openTime:715,closeTime:1815},capacity:103,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:11,empty:0},{ age:1,capacity:14,empty:0},{ age:2,capacity:18,empty:1},{ age:3,capacity:18,empty:0},{ age:5,capacity:42,empty:9}],category:0,},
  {id:'13',name:'南長崎第二保育園',position: {lat:35.7237741,lng:139.6891844},address:'東京都豊島区南長崎2-3-21',tel:'03-3952-4761',company:'',established:'1976年11月1日',url:'',hour:{openTime:715,closeTime:1815},capacity:96,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:9,empty:0},{ age:1,capacity:15,empty:0},{ age:2,capacity:16,empty:0},{ age:3,capacity:16,empty:0},{ age:5,capacity:40,empty:2}],category:0,},
  {id:'14',name:'長崎保育園',position: {lat:35.7288807,lng:139.6887691},address:'東京都豊島区長崎3-7-7',tel:'03-3955-6171',company:'',established:'1974年8月1日',url:'',hour:{openTime:715,closeTime:1815},capacity:104,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:12,empty:0},{ age:1,capacity:16,empty:1},{ age:2,capacity:18,empty:1},{ age:3,capacity:18,empty:0},{ age:5,capacity:40,empty:9}],category:0,},
  {id:'15',name:'要町保育園',position: {lat:35.7374544,lng:139.6865539},address:'東京都豊島区要町3-17-11',tel:'03-3955-6141',company:'',established:'1968年10月1日',url:'',hour:{openTime:715,closeTime:1815},capacity:102,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:14,empty:0},{ age:1,capacity:15,empty:0},{ age:2,capacity:16,empty:0},{ age:3,capacity:16,empty:0},{ age:5,capacity:41,empty:2}],category:0,},
  {id:'16',name:'高松第二保育園',position: {lat:35.7361641,lng:139.6987539},address:'東京都豊島区高松1-7-13',tel:'03-3955-8421',company:'',established:'1968年10月15日',url:'',hour:{openTime:715,closeTime:1815},capacity:101,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:10,empty:0},{ age:1,capacity:15,empty:0},{ age:2,capacity:18,empty:0},{ age:3,capacity:18,empty:0},{ age:5,capacity:40,empty:0}],category:0,},
  {id:'17',name:'駒込第三保育園',position: {lat:35.7373927999999,lng:139.7478809},address:'東京都豊島区駒込2-2-3',tel:'03-3915-8677',company:'社会福祉法人豊島区社会福祉事業団(東京都豊島区西巣鴨2-30-20)',established:'1981年8月1日',url:'http://www.toshimaj.or.jp/',hour:{openTime:715,closeTime:1815},capacity:109,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:9,empty:0},{ age:1,capacity:15,empty:0},{ age:2,capacity:19,empty:1},{ age:3,capacity:21,empty:4},{ age:5,capacity:45,empty:1}],category:0,},
  {id:'18',name:'南大塚保育園',position: {lat:35.7290526,lng:139.7299459},address:'東京都豊島区南大塚2-36-3',tel:'03-3946-7688',company:'社会福祉法人豊島区社会福祉事業団 (東京都豊島区西巣鴨2-30-20)',established:'1975年5月1日',url:'http://www.toshimaj.or.jp/',hour:{openTime:715,closeTime:1815},capacity:106,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:12,empty:0},{ age:1,capacity:14,empty:0},{ age:2,capacity:18,empty:0},{ age:3,capacity:20,empty:0},{ age:5,capacity:42,empty:5}],category:0,},
  {id:'19',name:'ミアヘルサ保育園ひびき駒込',position: {lat:35.7368089,lng:139.7490212},address:'東京都豊島区駒込1-28-16 本澤ビル2階、3階',tel:'03-6912-1407',company:'ミアヘルサ株式会社（東京都新宿区市谷仲之町3-19）',established:'2017年4月1日',url:'https://www.merhalsa.jp/hoikuen/',hour:{openTime:715,closeTime:1915},capacity:20,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:6,empty:5},{ age:1,capacity:7,empty:0},{ age:2,capacity:7,empty:4},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:0,},
  {id:'20',name:'コンビプラザ駒込ちどり保育園',position: {lat:35.7374766999999,lng:139.7459003},address:'東京都豊島区駒込3-3-19 ORCHID PLACE2階、3階',tel:'03-6903-6531',company:'コンビウィズ株式会社（東京都台東区元浅草2-6-7）',established:'2016年4月1日',url:'https://www.combiwith.co.jp/support/plaza/chidori/',hour:{openTime:715,closeTime:2015},capacity:70,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:2},{ age:1,capacity:12,empty:0},{ age:2,capacity:12,empty:1},{ age:3,capacity:12,empty:3},{ age:5,capacity:28,empty:2}],category:0,},
  {id:'21',name:'さくらさくみらい 駒込',position: {lat:35.7382038,lng:139.7451976},address:'東京都豊島区駒込3-21-4',tel:'03-5980-9379',company:'株式会社さくらさくみらい（東京都千代田区有楽町1-2-2 東宝日比谷ビル）',established:'2016年9月1日',url:'https://www.sakura-39.jp/',hour:{openTime:715,closeTime:2015},capacity:63,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:2},{ age:1,capacity:10,empty:0},{ age:2,capacity:11,empty:2},{ age:3,capacity:12,empty:3},{ age:5,capacity:24,empty:7}],category:0,},
  {id:'22',name:'キッズパートナー駒込',position: {lat:35.7366323,lng:139.7425899},address:'東京都豊島区駒込4-4-9',tel:'03-5980-9130',company:'ケアパートナー株式会社（東京都港区港南2-16-1品川イーストワンタワー21階）',established:'2020年4月1日',url:'https://www.care-partner.com/service/childcare/komagome/',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:12,empty:0},{ age:3,capacity:12,empty:4},{ age:5,capacity:20,empty:1}],category:0,},
  {id:'23',name:'駒込第二若草保育園',position: {lat:35.7360246,lng:139.7379641},address:'東京都豊島区駒込5-1-3',tel:'03-3949-9152',company:'社会福祉法人若草保育園（東京都豊島区南大塚1-10-3）',established:'2021年4月1日',url:'https://komagome2-nurs.web-wakakusa.jp/',hour:{openTime:715,closeTime:2015},capacity:100,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:10,empty:0},{ age:1,capacity:14,empty:0},{ age:2,capacity:17,empty:0},{ age:3,capacity:17,empty:1},{ age:5,capacity:42,empty:4}],category:0,},
  {id:'24',name:'太陽の子 巣鴨駅前保育園',position: {lat:35.7332612,lng:139.7409761},address:'東京都豊島区巣鴨1-14-8 中野ビル2階、3階',tel:'03-5981-7840',company:'HITOWAキッズライフ株式会社（東京都港区港南2-15-3品川インターシティC棟15階）',established:'2015年4月1日',url:'https://www.kidslife-nursery.com',hour:{openTime:715,closeTime:2015},capacity:70,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:1},{ age:2,capacity:12,empty:2},{ age:3,capacity:12,empty:1},{ age:5,capacity:30,empty:9}],category:0,},
  {id:'25',name:'うぃず巣鴨駅前第二保育園',position: {lat:35.7321142,lng:139.7386167},address:'東京都豊島区巣鴨1-26-4',tel:'03-6902-1571',company:'株式会社WITH（埼玉県川口市飯塚1-2-16）',established:'2020年9月1日',url:'https://www.mom.ed.jp/hoikuen/t_sugamoekimae3.html',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:1, ageTo:5},availability:[{ age:0,capacity:0,empty:0},{ age:1,capacity:12,empty:0},{ age:2,capacity:12,empty:1},{ age:3,capacity:12,empty:1},{ age:5,capacity:24,empty:1}],category:0,},
  {id:'26',name:'うぃず巣鴨駅前保育園',position: {lat:35.7336919,lng:139.736919},address:'東京都豊島区巣鴨3-23-12',tel:'03-5980-7085',company:'株式会社WITH（埼玉県川口市飯塚1-2-16）',established:'2015年7月1日',url:'http://www.mom.ed.jp/hoikuen/t_sugamoekimae.html',hour:{openTime:715,closeTime:2015},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:11,empty:0},{ age:3,capacity:11,empty:0},{ age:5,capacity:22,empty:0}],category:0,},
  {id:'27',name:'西巣鴨さくらそう保育園',position: {lat:35.7356038,lng:139.7295448},address:'東京都豊島区西巣鴨1-1-13',tel:'03-5907-5110',company:'社会福祉法人豊島区社会福祉事業団（東京都豊島区西巣鴨2-30-20）',established:'2014年4月1日',url:'https://toshimaj.or.jp/',hour:{openTime:715,closeTime:2015},capacity:136,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:12,empty:0},{ age:1,capacity:24,empty:1},{ age:2,capacity:24,empty:1},{ age:3,capacity:24,empty:0},{ age:5,capacity:52,empty:4}],category:0,},
  {id:'28',name:'キッズガーデン西巣鴨',position: {lat:35.7394268,lng:139.7246993},address:'東京都豊島区西巣鴨1-15-12',tel:'03-5944-5360',company:'株式会社Kids Smile Project（東京都品川区西五反田1-3-8 五反田御幸ビル7階）',established:'2019年7月1日',url:'https://www.kidsgarden.co.jp/',hour:{openTime:715,closeTime:1915},capacity:50,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:3},{ age:1,capacity:7,empty:2},{ age:2,capacity:10,empty:1},{ age:3,capacity:10,empty:3},{ age:5,capacity:17,empty:13}],category:0,},
  {id:'29',name:'おはよう保育園 西巣鴨',position: {lat:35.7364677,lng:139.7269639},address:'東京都豊島区西巣鴨1-22-8',tel:'03-6903-4560',company:'株式会社おはようキッズ（東京都千代田区富士見2-14-36）',established:'2018年4月1日',url:'https://www.ohayokids.co.jp/',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:5},{ age:2,capacity:11,empty:0},{ age:3,capacity:11,empty:0},{ age:5,capacity:22,empty:5}],category:0,},
  {id:'30',name:'西巣鴨・学びの保育園',position: {lat:35.7443594,lng:139.7286799},address:'東京都豊島区西巣鴨4-13-5 朝日シティパリオ西巣鴨2階',tel:'03-6903-7233',company:'社会福祉法人育木会（千葉県松戸市秋山2丁目5番地の1）',established:'2016年4月1日',url:'http://nishisugamo.manabien.net/aboutus.html',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:12,empty:1},{ age:3,capacity:12,empty:2},{ age:5,capacity:20,empty:1}],category:0,},
  {id:'31',name:'グローバルキッズ西巣鴨園',position: {lat:35.7444313,lng:139.7303086},address:'東京都豊島区西巣鴨4-20-4 ヴェルテラスすずき1階',tel:'03-3576-0666',company:'株式会社グローバルキッズ（東京都千代田区富士見2-14-26）',established:'2017年4月1日',url:'http://www.gkids.co.jp/facilities/nursery_017.html',hour:{openTime:715,closeTime:1915},capacity:20,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:6,empty:4},{ age:1,capacity:10,empty:0},{ age:2,capacity:4,empty:1},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:0,},
  {id:'32',name:'おおつかほうゆう保育園',position: {lat:35.7327936999999,lng:139.7317443},address:'東京都豊島区北大塚1-21-13',tel:'03-3576-2200',company:'社会福祉法人豊友会（兵庫県豊岡市下陰字西浅黒5）',established:'2022年4月1日',url:'https://houyuukai.jp/',hour:{openTime:715,closeTime:1915},capacity:56,age:{ageFrom:1, ageTo:5},availability:[{ age:0,capacity:0,empty:0},{ age:1,capacity:14,empty:7},{ age:2,capacity:14,empty:0},{ age:3,capacity:14,empty:4},{ age:5,capacity:14,empty:1}],category:0,},
  {id:'33',name:'アンソレイユ保育園',position: {lat:35.7342447,lng:139.7227504},address:'東京都豊島区北大塚3-12-12',tel:'03-6903-7410',company:'社会福祉法人みどりの森（埼玉県さいたま市見沼区大字南中丸939-5番地）',established:'2015年4月1日',url:'http://www.ensoleille-hoikuen.jp/',hour:{openTime:715,closeTime:2015},capacity:110,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:9,empty:0},{ age:1,capacity:18,empty:0},{ age:2,capacity:18,empty:0},{ age:3,capacity:21,empty:0},{ age:5,capacity:44,empty:2}],category:0,},
  {id:'34',name:'東進ポップキッズ 大塚キャンパス',position: {lat:35.7353400999999,lng:139.725448},address:'東京都豊島区北大塚3-31-3 スプリングヒルズ',tel:'03-5974-2525',company:'社会福祉法人東進（千葉県松戸市高塚新田450-11）',established:'2015年9月1日',url:'該当なし',hour:{openTime:715,closeTime:2015},capacity:67,age:{ageFrom:1, ageTo:5},availability:[{ age:0,capacity:0,empty:0},{ age:1,capacity:10,empty:6},{ age:2,capacity:12,empty:1},{ age:3,capacity:15,empty:0},{ age:5,capacity:30,empty:3}],category:0,},
  {id:'35',name:'若草保育園',position: {lat:35.728103,lng:139.7363579},address:'東京都豊島区南大塚1-10-3',tel:'03-3945-6372',company:'社会福祉法人若草保育園（東京都豊島区南大塚1-10-3）',established:'1948年7月1日',url:'https://wakakusa-nurs.web-wakakusa.jp/',hour:{openTime:715,closeTime:1915},capacity:150,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:15,empty:9},{ age:1,capacity:28,empty:6},{ age:2,capacity:28,empty:1},{ age:3,capacity:29,empty:0},{ age:5,capacity:50,empty:1}],category:0,},
  {id:'36',name:'大空と大地のなーさりぃ第一南大塚園',position: {lat:35.7281807,lng:139.7297841},address:'東京都豊島区南大塚2-26-15 南大塚ビル2階',tel:'03-6902-9891',company:'株式会社キッズコーポレーション（栃木県宇都宮市南大通り2-6-1 KIDS 1ST BLD）',established:'2018年4月1日',url:'https://kids-21.co.jp/oozora-daichi/facilities/m-otsuka/',hour:{openTime:715,closeTime:1915},capacity:66,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:11,empty:0},{ age:3,capacity:13,empty:1},{ age:5,capacity:26,empty:2}],category:0,},
  {id:'37',name:'大空と大地のなーさりぃ第二南大塚園',position: {lat:35.7281807,lng:139.7297841},address:'東京都豊島区南大塚2-26-15 南大塚ビル3階',tel:'03-6902-2871',company:'株式会社キッズコーポレーション（栃木県宇都宮市南大通り2-6-1 KIDS 1ST BLD）',established:'2019年4月1日',url:'https://kids-21.co.jp/oozora-daichi/facilities/m-otsuka2/',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:10,empty:0},{ age:3,capacity:11,empty:3},{ age:5,capacity:23,empty:0}],category:0,},
  {id:'38',name:'キッズガーデン南大塚',position: {lat:35.7298143,lng:139.7262499},address:'東京都豊島区南大塚3-20-6 VORT大塚FT3階',tel:'03-5927-8435',company:'株式会社Kids Smile Project（東京都品川区西五反田1-3-8 五反田御幸ビル7階）',established:'2015年9月1日',url:'http://www.kidsgarden.co.jp/minamiotuka.html',hour:{openTime:715,closeTime:2015},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:1},{ age:2,capacity:11,empty:0},{ age:3,capacity:11,empty:0},{ age:5,capacity:22,empty:1}],category:0,},
  {id:'39',name:'大塚りとるぱんぷきんず',position: {lat:35.7314104,lng:139.7280204},address:'東京都豊島区南大塚3-33-1 JR大塚南口ビル5階',tel:'03-5928-0837',company:'社会福祉法人清香会（福岡県豊前市大字八屋1537番地の1）',established:'2013年9月1日',url:'https://www.seikoukai.or.jp/facilities/otsuka/',hour:{openTime:715,closeTime:2015},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:9,empty:0},{ age:2,capacity:10,empty:0},{ age:3,capacity:11,empty:0},{ age:5,capacity:24,empty:0}],category:0,},
  {id:'40',name:'ニチイキッズかみいけぶくろ保育園',position: {lat:35.7368,lng:139.7175979},address:'東京都豊島区上池袋2-35-15',tel:'03-5961-0560',company:'株式会社ニチイ学館（東京都千代田区神田駿河台4-6御茶ノ水ソラシティ）',established:'2019年4月1日',url:'http://www.nichiikids.net/',hour:{openTime:715,closeTime:1915},capacity:70,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:3},{ age:1,capacity:10,empty:3},{ age:2,capacity:14,empty:2},{ age:3,capacity:14,empty:2},{ age:5,capacity:26,empty:7}],category:0,},
  {id:'41',name:'キッズガーデン上池袋',position: {lat:35.7411005,lng:139.7182047},address:'東京都豊島区上池袋4-29-93階',tel:'03-5972-1711',company:'株式会社Kids Smile Project（東京都品川区西五反田1-3-8 五反田御幸ビル7階）',established:'2020年7月1日',url:'https://www.kidsgarden.co.jp/nursery/guide/kamiikebukuro/',hour:{openTime:715,closeTime:1915},capacity:49,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:1},{ age:1,capacity:9,empty:6},{ age:2,capacity:9,empty:0},{ age:3,capacity:10,empty:4},{ age:5,capacity:15,empty:2}],category:0,},
  {id:'42',name:'さくらさくみらい 上池袋',position: {lat:35.7440777,lng:139.7194087},address:'東京都豊島区上池袋4-47-9',tel:'03-6903-7715',company:'株式会社さくらさくみらい（東京都千代田区有楽町1-2-2 東宝日比谷ビル）',established:'2018年10月1日',url:'https://www.sakura-39.jp/',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:1},{ age:1,capacity:10,empty:3},{ age:2,capacity:10,empty:0},{ age:3,capacity:12,empty:2},{ age:5,capacity:22,empty:7}],category:0,},
  {id:'43',name:'まちの保育園 東池袋',position: {lat:35.7298777,lng:139.7240067},address:'東京都豊島区東池袋2-6-2 ロイヤルアネックス1階',tel:'03-6915-2773',company:'社会福祉法人毛里田睦会（群馬県太田市矢田堀町388-3）',established:'2017年4月1日',url:'https://www.machino-higashiikebukuro.com/',hour:{openTime:715,closeTime:1915},capacity:49,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:8,empty:4},{ age:2,capacity:8,empty:0},{ age:3,capacity:9,empty:1},{ age:5,capacity:18,empty:4}],category:0,},
  {id:'44',name:'東池袋第一保育園',position: {lat:35.733496,lng:139.7178305},address:'東京都豊島区東池袋2-60-19',tel:'03-3987-4621',company:'HITOWAキッズライフ株式会社（東京都港区港南2-15-3品川インターシティC棟15階）',established:'2023年4月1日',url:'https://www.kidslife-nursery.com',hour:{openTime:715,closeTime:2015},capacity:110,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:9,empty:0},{ age:1,capacity:19,empty:0},{ age:2,capacity:19,empty:0},{ age:3,capacity:20,empty:3},{ age:5,capacity:43,empty:11}],category:0,},
  {id:'45',name:'AIAI NURSERY 第一東池袋',position: {lat:35.7310896,lng:139.7209561},address:'東京都豊島区東池袋3-22-17 東池袋セントラルプレイス1階',tel:'03-5927-9024',company:'AIAI Child Care 株式会社（東京都墨田区錦糸1-2-1 アルカセントラル16階）',established:'2016年11月1日',url:'https://aiai.globalchildcare.biz/facility/higashi-ikebukuro/',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:11,empty:0},{ age:3,capacity:11,empty:0},{ age:5,capacity:22,empty:5}],category:0,},
  {id:'46',name:'AIAI NURSERY 第二東池袋',position: {lat:35.7257079,lng:139.7203395},address:'東京都豊島区東池袋4-2-1-201号・プラウドタワー東池袋ステーションアリーナ2階',tel:'03-6903-1984',company:'AIAI Child Care 株式会社（東京都墨田区錦糸1-2-1・アルカセントラル16階）',established:'2022年4月1日',url:'https://aiai.globalchildcare.biz/',hour:{openTime:715,closeTime:1915},capacity:50,age:{ageFrom:1, ageTo:5},availability:[{ age:0,capacity:0,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:10,empty:0},{ age:3,capacity:10,empty:0},{ age:5,capacity:20,empty:4}],category:0,},
  {id:'47',name:'クオリスキッズ東池袋保育園',position: {lat:35.7271097,lng:139.7228308},address:'東京都豊島区東池袋4-34-9',tel:'03-6709-1411',company:'株式会社クオリス（大阪府大阪市浪速区難波中1-12-5）',established:'2021年4月1日',url:'https://quolis-kids.com/114_higashiikebukuro/?region=tokyo&ward=toshima-ku#school-leaf',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:1, ageTo:5},availability:[{ age:0,capacity:0,empty:0},{ age:1,capacity:12,empty:1},{ age:2,capacity:12,empty:0},{ age:3,capacity:12,empty:1},{ age:5,capacity:24,empty:7}],category:0,},
  {id:'48',name:'グローバルキッズ東池袋園',position: {lat:35.7260311,lng:139.7165303},address:'東京都豊島区南池袋2-45-3 としまエコミューゼタウン2階',tel:'03-3983-2557',company:'株式会社グローバルキッズ（東京都千代田区富士見2-14-36）',established:'2017年4月1日',url:'http://www.gkids.co.jp/facilities/nursery_101.html',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:11,empty:0},{ age:3,capacity:11,empty:0},{ age:5,capacity:22,empty:3}],category:0,},
  {id:'49',name:'同援さくら保育園',position: {lat:35.7234533,lng:139.7123195},address:'東京都豊島区南池袋3-7-8',tel:'03-5957-7510',company:'社会福祉法人恩賜財団東京都同胞援護会（東京都新宿区原町3-8）',established:'2006年4月1日',url:'http://doen.jp/introduction/nuture/sakura',hour:{openTime:715,closeTime:2215},capacity:110,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:12,empty:0},{ age:1,capacity:18,empty:0},{ age:2,capacity:20,empty:0},{ age:3,capacity:20,empty:0},{ age:5,capacity:40,empty:1}],category:0,},
  {id:'50',name:'ミアヘルサ保育園ひびき池袋',position: {lat:35.7243742,lng:139.7120656},address:'東京都豊島区南池袋3-10-6',tel:'03-5924-6525',company:'ミアヘルサ株式会社（東京都新宿区市谷仲之町3-19）',established:'2019年4月1日',url:'https://www.merhalsa.jp/hoikuen/',hour:{openTime:715,closeTime:1915},capacity:46,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:4,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:11,empty:0},{ age:3,capacity:10,empty:5},{ age:5,capacity:11,empty:1}],category:0,},
  {id:'51',name:'ピオーネ雑司が谷保育園',position: {lat:35.722157,lng:139.7171681},address:'東京都豊島区南池袋4-7-8',tel:'03-3980-1161',company:'社会福祉法人桃山福祉会（岡山県岡山市北区菅野4291番地3）',established:'2019年4月1日',url:'https://www.isto.or.jp/pione-zoushigaya/',hour:{openTime:715,closeTime:1915},capacity:40,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:7,empty:0},{ age:2,capacity:8,empty:1},{ age:3,capacity:8,empty:2},{ age:5,capacity:11,empty:3}],category:0,},
  {id:'52',name:'グローバルキッズ池袋駅前保育園',position: {lat:35.7309104,lng:139.7081966},address:'東京都豊島区西池袋1丁目17番1号 東京都豊島合同庁舎1階',tel:'03-6903-1585',company:'株式会社グローバルキッズ（東京都千代田区富士見2-14-36）',established:'2018年4月1日',url:'http://www.gkids.co.jp/',hour:{openTime:715,closeTime:1915},capacity:20,age:{ageFrom:1, ageTo:2},availability:[{ age:0,capacity:0,empty:0},{ age:1,capacity:10,empty:10},{ age:2,capacity:10,empty:3},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:0,},
  {id:'53',name:'ベネッセ 目白保育園',position: {lat:35.7258621,lng:139.7041153},address:'東京都豊島区西池袋2-6-20',tel:'03-5956-1640',company:'株式会社ベネッセスタイルケア（東京都新宿区西新宿2-3-1 新宿モノリスビル5階）',established:'2019年4月1日',url:'https://hoiku.benesse-style-care.co.jp/facilities/area_tokyo/toshima/h-mejiro/',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:11,empty:0},{ age:3,capacity:11,empty:0},{ age:5,capacity:22,empty:1}],category:0,},
  {id:'54',name:'グローバルキッズ西池袋園',position: {lat:35.7257763,lng:139.7066642},address:'東京都豊島区西池袋2-19-5 ラクレーシュ目白',tel:'03-5956-0660',company:'株式会社グローバルキッズ（東京都千代田区富士見2-14-36）',established:'2016年4月1日',url:'http://www.gkids.co.jp/facilities/nursery_080.html',hour:{openTime:715,closeTime:2015},capacity:110,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:2},{ age:1,capacity:19,empty:0},{ age:2,capacity:22,empty:0},{ age:3,capacity:22,empty:3},{ age:5,capacity:41,empty:0}],category:0,},
  {id:'55',name:'西池袋そらいろ保育園',position: {lat:35.7262142,lng:139.7084894},address:'東京都豊島区西池袋2-25-20',tel:'03-3988-4210',company:'社会福祉法人みつばち会（東京都港区白金4-7-2）',established:'2009年4月1日',url:'http://sorairo.mitsu-bachi.net/',hour:{openTime:715,closeTime:2015},capacity:105,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:9,empty:0},{ age:1,capacity:18,empty:0},{ age:2,capacity:18,empty:0},{ age:3,capacity:20,empty:0},{ age:5,capacity:40,empty:2}],category:0,},
  {id:'56',name:'いけぶくろこころ保育園',position: {lat:35.7371130999999,lng:139.7134003},address:'東京都豊島区池袋1-13-18 音ノ葉イマス池袋ビル3階',tel:'03-5904-9471',company:'社会福祉法人こころ福祉会（東京都千代田区外神田1-18-13 秋葉原ダイビル9階）',established:'2017年4月1日',url:'http://cocoro-fukushikai.or.jp/',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:12,empty:5},{ age:2,capacity:12,empty:1},{ age:3,capacity:12,empty:0},{ age:5,capacity:18,empty:4}],category:0,},
  {id:'57',name:'明日葉保育園池袋園',position: {lat:35.7352458,lng:139.7076463},address:'東京都豊島区池袋3-58-15',tel:'03-3986-4006',company:'株式会社あしたばマインド（東京都港区芝四丁目13-3・PMO田町東10F）',established:'2022年4月1日',url:'https://www.ashita-ba.jp/hoiku/ikebukuro/',hour:{openTime:715,closeTime:2015},capacity:100,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:8,empty:0},{ age:1,capacity:18,empty:0},{ age:2,capacity:18,empty:0},{ age:3,capacity:18,empty:1},{ age:5,capacity:38,empty:6}],category:0,},
  {id:'58',name:'わくわく保育園',position: {lat:35.7382666,lng:139.7073542},address:'東京都豊島区池袋4-10-2',tel:'03-6912-7091',company:'社会福祉法人泉湧く家（東京都豊島区池袋3-59-4）',established:'2017年4月1日',url:'http://www.izumiwaku-ie.jp/wakuwaku',hour:{openTime:715,closeTime:1915},capacity:30,age:{ageFrom:1, ageTo:5},availability:[{ age:0,capacity:0,empty:0},{ age:1,capacity:5,empty:0},{ age:2,capacity:5,empty:0},{ age:3,capacity:7,empty:4},{ age:5,capacity:13,empty:2}],category:0,},
  {id:'59',name:'グローバルキッズ北池袋園',position: {lat:35.739614,lng:139.7131452},address:'東京都豊島区池袋本町1-26-5',tel:'03-3982-1188',company:'株式会社グローバルキッズ（東京都千代田区富士見2-14-36）',established:'2015年12月1日',url:'http://www.gkids.co.jp/facilities/nursery_076.html',hour:{openTime:715,closeTime:2015},capacity:70,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:11,empty:1},{ age:2,capacity:12,empty:0},{ age:3,capacity:13,empty:3},{ age:5,capacity:28,empty:4}],category:0,},
  {id:'60',name:'太陽の子 池袋本町保育園',position: {lat:35.7384154,lng:139.7153312},address:'東京都豊島区池袋本町1-31-2',tel:'03-5960-7600',company:'HITOWAキッズライフ株式会社（東京都港区港南2-15-3品川インターシティC棟15階）',established:'2016年4月1日',url:'http://www.kidslife-nursery.com/base/toshima/ikebukurohoncho/',hour:{openTime:715,closeTime:2015},capacity:20,age:{ageFrom:0, ageTo:4},availability:[{ age:0,capacity:3,empty:1},{ age:1,capacity:7,empty:3},{ age:2,capacity:7,empty:0},{ age:3,capacity:3,empty:0},{ age:5,capacity:0,empty:0}],category:0,},
  {id:'61',name:'みのり保育園',position: {lat:35.7449648,lng:139.7121227},address:'東京都豊島区池袋本町3-29-9',tel:'03-3983-2396',company:'社会福祉法人みのり愛の会（東京都豊島区池袋本町3-29-9）',established:'1970年4月1日',url:'https://www.minori-toshima.or.jp/minori/',hour:{openTime:715,closeTime:1915},capacity:40,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:3},{ age:1,capacity:8,empty:3},{ age:2,capacity:8,empty:2},{ age:3,capacity:10,empty:1},{ age:5,capacity:8,empty:2}],category:0,},
  {id:'62',name:'アスク池袋本町保育園',position: {lat:35.7455017,lng:139.710713},address:'東京都豊島区池袋本町3-34-17',tel:'03-5956-2311',company:'株式会社日本保育サービス（愛知県名古屋市中村区名駅二丁目38番2号）',established:'2016年4月1日',url:'http://www.nihonhoiku.co.jp/blog/ikebukurohoncho/',hour:{openTime:715,closeTime:2015},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:11,empty:1},{ age:3,capacity:11,empty:0},{ age:5,capacity:22,empty:2}],category:0,},
  {id:'63',name:'めぐみ保育園',position: {lat:35.7422158,lng:139.7165655},address:'東京都豊島区池袋本町4-1-14',tel:'03-5944-9791',company:'社会福祉法人みのり愛の会（東京都豊島区池袋本町3-29-9）',established:'2010年4月1日',url:'https://www.minori-toshima.or.jp/megumi',hour:{openTime:715,closeTime:2015},capacity:90,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:9,empty:5},{ age:1,capacity:13,empty:0},{ age:2,capacity:15,empty:1},{ age:3,capacity:15,empty:3},{ age:5,capacity:38,empty:4}],category:0,},
  {id:'64',name:'雑司が谷保育園',position: {lat:35.72123,lng:139.7195893},address:'東京都豊島区雑司が谷1-22-5',tel:'03-5954-4770',company:'社会福祉法人桜ヶ丘（東京都世田谷区桜丘4-19-33）',established:'2017年4月1日',url:'http://sakuragaoka.jpn.com/zoshigaya.html',hour:{openTime:715,closeTime:1915},capacity:98,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:10,empty:0},{ age:1,capacity:17,empty:0},{ age:2,capacity:17,empty:0},{ age:3,capacity:18,empty:0},{ age:5,capacity:36,empty:0}],category:0,},
  {id:'65',name:'雑司が谷すきっぷ保育園',position: {lat:35.7208624,lng:139.7170997},address:'東京都豊島区雑司が谷2-25-8',tel:'03-5843-3970',company:'株式会社俊英館(東京都板橋区小茂根4-9-2 セガミビル3階)',established:'2019年6月1日',url:'http://www.skip-hoikuen.com/',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:11,empty:1},{ age:3,capacity:11,empty:2},{ age:5,capacity:22,empty:6}],category:0,},
  {id:'66',name:'グローバルキッズ雑司が谷園',position: {lat:35.7215,lng:139.7128944},address:'東京都豊島区雑司が谷3-8-5',tel:'03-5951-0088',company:'株式会社グローバルキッズ（東京都千代田区富士見2-14-36）',established:'2017年4月1日',url:'http://www.gkids.co.jp/facilities/nursery_100.html',hour:{openTime:715,closeTime:1915},capacity:70,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:2},{ age:1,capacity:12,empty:0},{ age:2,capacity:12,empty:0},{ age:3,capacity:15,empty:0},{ age:5,capacity:25,empty:1}],category:0,},
  {id:'67',name:'ベネッセ 雑司が谷保育園',position: {lat:35.7217019,lng:139.712888},address:'東京都豊島区雑司が谷3-9-14 1・2階',tel:'03-5396-7331',company:'株式会社ベネッセスタイルケア（東京都新宿区西新宿2-3-1新宿モノリスビル）',established:'2020年4月1日',url:'https://hoiku.benesse-style-care.co.jp/facilities/area_tokyo/toshima/h-zoshigaya/',hour:{openTime:715,closeTime:1915},capacity:50,age:{ageFrom:1, ageTo:5},availability:[{ age:0,capacity:0,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:10,empty:0},{ age:3,capacity:10,empty:2},{ age:5,capacity:20,empty:8}],category:0,},
  {id:'68',name:'ミアヘルサ保育園ひびき高田',position: {lat:35.7134434,lng:139.7189541},address:'東京都豊島区高田1-11-16',tel:'03-5843-3722',company:'ミアヘルサ株式会社（東京都新宿区市谷仲之町3-19）',established:'2022年4月1日',url:'https://www.merhalsa.jp/hoikuen/',hour:{openTime:715,closeTime:1915},capacity:52,age:{ageFrom:1, ageTo:5},availability:[{ age:0,capacity:0,empty:0},{ age:1,capacity:12,empty:0},{ age:2,capacity:13,empty:1},{ age:3,capacity:13,empty:1},{ age:5,capacity:14,empty:2}],category:0,},
  {id:'69',name:'目白ちとせ保育園',position: {lat:35.7162831,lng:139.7097677},address:'東京都豊島区高田3-36-11',tel:'03-6907-1680',company:'社会福祉法人ちとせ交友会（東京都千代田区二番町7-5）',established:'2018年4月1日',url:'http://www.chitosek.or.jp/',hour:{openTime:715,closeTime:1915},capacity:50,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:9,empty:0},{ age:2,capacity:9,empty:0},{ age:3,capacity:8,empty:0},{ age:5,capacity:18,empty:0}],category:0,},
  {id:'70',name:'目白ひかり保育園',position: {lat:35.7213178,lng:139.709219},address:'東京都豊島区目白2-21-29',tel:'03-3983-1188',company:'社会福祉法人敬愛健伸会(東京都文京区白山2-29-9)',established:'2019年4月1日',url:'http://www.m-hikari.org/',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:11,empty:0},{ age:3,capacity:11,empty:4},{ age:5,capacity:22,empty:5}],category:0,},
  {id:'71',name:'グローバルキッズ椎名町園',position: {lat:35.7238133,lng:139.6961431},address:'東京都豊島区目白5-18-23',tel:'03-6908-2391',company:'株式会社グローバルキッズ（東京都千代田区富士見2-14-36）',established:'2018年4月1日',url:'http://www.gkids.co.jp/',hour:{openTime:715,closeTime:1915},capacity:50,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:5,empty:3},{ age:1,capacity:10,empty:0},{ age:2,capacity:9,empty:0},{ age:3,capacity:9,empty:0},{ age:5,capacity:17,empty:0}],category:0,},
  {id:'72',name:'おはよう保育園 椎名町',position: {lat:35.7237134,lng:139.6886571},address:'東京都豊島区南長崎2-3-7',tel:'03-6908-0723',company:'株式会社おはようキッズ（東京都千代田区富士見2-14-36）',established:'2018年4月1日',url:'https://www.ohayokids.co.jp/',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:4},{ age:1,capacity:10,empty:1},{ age:2,capacity:11,empty:1},{ age:3,capacity:11,empty:3},{ age:5,capacity:22,empty:0}],category:0,},
  {id:'73',name:'椎名町ひまわり保育園',position: {lat:35.7271454,lng:139.6892829},address:'東京都豊島区南長崎3-35-8',tel:'03-3951-4009',company:'社会福祉法人育和会（東京都中野区上鷺宮3-8-8）',established:'1968年7月1日',url:'http://ikuwakai.net/himawari/',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:11,empty:0},{ age:3,capacity:11,empty:0},{ age:5,capacity:22,empty:3}],category:0,},
  {id:'74',name:'にじいろ保育園落合南長崎',position: {lat:35.7256942,lng:139.6840131},address:'東京都豊島区南長崎4-18-4',tel:'03-6908-0667',company:'ライクキッズ株式会社（東京都渋谷区道玄坂1-12-1）',established:'2018年4月1日',url:'https://www.like-kd.co.jp/',hour:{openTime:715,closeTime:1915},capacity:73,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:1},{ age:1,capacity:10,empty:0},{ age:2,capacity:12,empty:0},{ age:3,capacity:15,empty:2},{ age:5,capacity:30,empty:1}],category:0,},
  {id:'75',name:'グローバルキッズ東長崎園',position: {lat:35.7279472,lng:139.6866122},address:'東京都豊島区南長崎4-44-4',tel:'03-5983-0707',company:'株式会社グローバルキッズ（東京都千代田区富士見2-14-36）',established:'2016年4月1日',url:'http://www.gkids.co.jp/facilities/nursery_081.html',hour:{openTime:715,closeTime:2015},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:2},{ age:1,capacity:10,empty:0},{ age:2,capacity:12,empty:3},{ age:3,capacity:10,empty:0},{ age:5,capacity:22,empty:4}],category:0,},
  {id:'76',name:'まなびの森保育園東長崎',position: {lat:35.7287821,lng:139.6815063},address:'東京都豊島区南長崎5-20-17',tel:'03-3950-1121',company:'株式会社こどもの森（東京都国分寺市光町2-5-1）',established:'2017年4月1日',url:'http://www.kodomonomori.co.jp/',hour:{openTime:715,closeTime:2015},capacity:70,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:12,empty:0},{ age:3,capacity:14,empty:2},{ age:5,capacity:28,empty:1}],category:0,},
  {id:'77',name:'Nicot東長崎',position: {lat:35.7296002,lng:139.6835401},address:'東京都豊島区南長崎5-33-7 3階',tel:'03-6908-2135',company:'株式会社ポピンズエデュケア（東京都渋谷区広尾5-6-6）',established:'2019年9月1日',url:'https://www.poppins.co.jp/nursery/room/',hour:{openTime:715,closeTime:1915},capacity:50,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:1},{ age:1,capacity:8,empty:0},{ age:2,capacity:9,empty:3},{ age:3,capacity:9,empty:3},{ age:5,capacity:18,empty:5}],category:0,},
  {id:'78',name:'椎名町ちとせ保育園',position: {lat:35.726828,lng:139.6937499},address:'東京都豊島区長崎1-1-14',tel:'03-6905-8670',company:'社会福祉法人ちとせ交友会（東京都千代田区二番町7-5）',established:'2018年4月1日',url:'http://www.chitosek.or.jp/',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:9,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:10,empty:1},{ age:3,capacity:10,empty:0},{ age:5,capacity:21,empty:1}],category:0,},
  {id:'79',name:'ミアヘルサ保育園ゆらりん椎名町',position: {lat:35.7279148,lng:139.6962869},address:'東京都豊島区長崎1-10-8 ネクストワン椎名町1階',tel:'03-6909-3577',company:'ミアヘルサ株式会社（東京都新宿区市谷仲之町3-19）',established:'2024年4月1日',url:'https://hoiku.merhalsa.jp/yurarin/',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:1},{ age:1,capacity:10,empty:0},{ age:2,capacity:11,empty:0},{ age:3,capacity:11,empty:0},{ age:5,capacity:22,empty:3}],category:0,},
  {id:'80',name:'アスク長崎一丁目保育園',position: {lat:35.7293461,lng:139.6941986},address:'東京都豊島区長崎1-19-14',tel:'03-5917-4850',company:'株式会社日本保育サービス（愛知県名古屋市中村区名駅二丁目38番2号）',established:'2017年4月1日',url:'http://www.nihonhoiku.co.jp/blog/nagasaki1/',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:11,empty:0},{ age:3,capacity:11,empty:1},{ age:5,capacity:22,empty:0}],category:0,},
  {id:'81',name:'愛の家保育園',position: {lat:35.7301285,lng:139.6844453},address:'東京都豊島区長崎4-11-3',tel:'03-3957-2801',company:'社会福祉法人愛の家（東京都豊島区長崎4-11-3）',established:'1948年7月1日',url:'http://ainoie-hoikuen.com/index.html/',hour:{openTime:715,closeTime:1915},capacity:22,age:{ageFrom:1, ageTo:5},availability:[{ age:0,capacity:0,empty:1},{ age:1,capacity:2,empty:2},{ age:2,capacity:8,empty:2},{ age:3,capacity:4,empty:0},{ age:5,capacity:8,empty:0}],category:0,},
  {id:'82',name:'長崎森のなかま保育園',position: {lat:35.7320058,lng:139.68194},address:'東京都豊島区長崎5-7-2 2・3階',tel:'03-5926-6843',company:'シンリツ株式会社（埼玉県さいたま市南区鹿手袋4-31-17）',established:'2020年4月1日',url:'https://morinonakama.jp/nnk_hns',hour:{openTime:715,closeTime:1915},capacity:49,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:3,empty:2},{ age:1,capacity:8,empty:0},{ age:2,capacity:8,empty:2},{ age:3,capacity:10,empty:2},{ age:5,capacity:20,empty:7}],category:0,},
  {id:'83',name:'にじいろ保育園千早',position: {lat:35.7308048,lng:139.6941654},address:'東京都豊島区千早1-6-7',tel:'03-5926-6341',company:'ライクキッズ株式会社（東京都渋谷区道玄坂1-12-1）',established:'2018年4月1日',url:'https://www.like-kd.co.jp/',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:11,empty:0},{ age:3,capacity:11,empty:3},{ age:5,capacity:22,empty:1}],category:0,},
  {id:'84',name:'しいの実保育園',position: {lat:35.7326681,lng:139.6938375},address:'東京都豊島区千早1-31-5',tel:'03-3554-4103',company:'社会福祉法人育和会（東京都中野区上鷺宮3-8-8）',established:'1986年4月1日',url:'http://ikuwakai.net/siinomi/',hour:{openTime:715,closeTime:2215},capacity:110,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:12,empty:0},{ age:1,capacity:18,empty:0},{ age:2,capacity:20,empty:0},{ age:3,capacity:20,empty:0},{ age:5,capacity:40,empty:0}],category:0,},
  {id:'85',name:'千早子どもの家保育園',position: {lat:35.7350544,lng:139.6864375},address:'東京都豊島区千早3-37-14',tel:'03-3955-7028',company:'社会福祉法人千早子どもの家（東京都豊島区千早3-37-14）',established:'1969年6月1日',url:'http://www.chihaya-kids.jp/',hour:{openTime:715,closeTime:1915},capacity:60,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:9,empty:3},{ age:1,capacity:10,empty:0},{ age:2,capacity:12,empty:2},{ age:3,capacity:12,empty:1},{ age:5,capacity:17,empty:0}],category:0,},
  {id:'86',name:'グローバルキッズ千早園',position: {lat:35.737093,lng:139.6870229},address:'東京都豊島区千早3-45-11',tel:'03-5926-6506',company:'株式会社グローバルキッズ（東京都千代田区富士見2-14-36）',established:'2018年4月1日',url:'http://www.gkids.co.jp/',hour:{openTime:715,closeTime:1915},capacity:30,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:6,empty:2},{ age:1,capacity:12,empty:1},{ age:2,capacity:12,empty:6},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:0,},
  {id:'87',name:'うぃず千川保育園',position: {lat:35.7354796,lng:139.6879723},address:'東京都豊島区千早3-40-1',tel:'03-5926-8390',company:'株式会社WITH（埼玉県川口市飯塚1-2-16）',established:'2023年4月1日',url:'https://preschool.mom.ed.jp/t_senkawa.html',hour:{openTime:715,closeTime:1915},capacity:68,age:{ageFrom:1, ageTo:5},availability:[{ age:0,capacity:0,empty:0},{ age:1,capacity:13,empty:0},{ age:2,capacity:14,empty:0},{ age:3,capacity:14,empty:0},{ age:5,capacity:27,empty:5}],category:0,},
  {id:'88',name:'AIAI NURSERY 要町',position: {lat:35.7338540999999,lng:139.6950311},address:'東京都豊島区要町1-8-11 2階',tel:'03-5926-9001',company:'AIAI Child Care 株式会社（東京都墨田区錦糸1-2-1 アルカセントラル16階）',established:'2016年4月1日',url:'https://aiai.globalchildcare.biz/facility/kaname/',hour:{openTime:715,closeTime:1915},capacity:70,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:12,empty:0},{ age:2,capacity:13,empty:0},{ age:3,capacity:13,empty:1},{ age:5,capacity:26,empty:0}],category:0,},
  {id:'89',name:'太陽の子 要町保育園',position: {lat:35.7336456,lng:139.6981855},address:'東京都豊島区要町1-9-1 彌栄ビルI 3階',tel:'03-6909-5433',company:'HITOWAキッズライフ株式会社（東京都港区港南2-15-3品川インターシティC棟15階）',established:'2017年4月1日',url:'http://www.kidslife-nursery.com/base/toshima/kanamecho/',hour:{openTime:715,closeTime:1915},capacity:56,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:10,empty:0},{ age:3,capacity:10,empty:0},{ age:5,capacity:20,empty:0}],category:0,},
  {id:'90',name:'キッズガーデン要町',position: {lat:35.7350238,lng:139.6949858},address:'東京都豊島区要町1-25-7',tel:'03-5948-3991',company:'株式会社Kids Smile Project（東京都品川区西五反田1-3-8 五反田御幸ビル7階）',established:'2018年4月1日',url:'https://www.kidsgarden.co.jp/',hour:{openTime:715,closeTime:1915},capacity:73,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:10,empty:0},{ age:2,capacity:12,empty:0},{ age:3,capacity:15,empty:1},{ age:5,capacity:30,empty:0}],category:0,},
  {id:'91',name:'アスクかなめ町保育園',position: {lat:35.7387056,lng:139.6883656},address:'東京都豊島区要町3-22-10 星野館ビル1階',tel:'03-5986-8021',company:'株式会社日本保育サービス（愛知県名古屋市中村区名駅二丁目38番2号）',established:'2018年4月1日',url:'https://www.nihonhoiku.co.jp/blog/kanamecho/',hour:{openTime:715,closeTime:1915},capacity:30,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:12,empty:1},{ age:2,capacity:12,empty:1},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:0,},
  {id:'92',name:'せんかわみんなの家',position: {lat:35.7404307,lng:139.6889734},address:'東京都豊島区要町3-54-8',tel:'03-3530-5735',company:'社会福祉法人つばさ福祉会（宮崎県西都市白馬町3番地）',established:'2015年4月1日',url:'http://www.tsubasa-f.or.jp/senkawa/',hour:{openTime:715,closeTime:2015},capacity:110,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:12,empty:0},{ age:1,capacity:18,empty:0},{ age:2,capacity:20,empty:0},{ age:3,capacity:20,empty:0},{ age:5,capacity:40,empty:2}],category:0,},
  {id:'93',name:'SAKURA保育園千川',position: {lat:35.741665,lng:139.6917157},address:'東京都豊島区千川2-30-2',tel:'03-5926-7403',company:'社会福祉法人慈光明徳会（熊本県球磨郡湯前町1962-1）',established:'2017年4月1日',url:'http://jikou-m.jp/senkawa/',hour:{openTime:715,closeTime:1915},capacity:66,age:{ageFrom:0, ageTo:5},availability:[{ age:0,capacity:6,empty:0},{ age:1,capacity:12,empty:1},{ age:2,capacity:12,empty:0},{ age:3,capacity:12,empty:0},{ age:5,capacity:24,empty:4}],category:0,},
  {id:'94',name:'このえ駒込小規模保育園',position: {lat:35.7378152,lng:139.745983},address:'東京都豊島区駒込3-3-16サンハイツ駒込１階',tel:'03-5972-4990',company:'株式会社なないろ（東京都港区港南2-15-3 品川インターシティC棟12階）',established:'2016年4月1日',url:'http://www.konoehoikuen.jp/',hour:{openTime:715,closeTime:1915},capacity:11,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:3,empty:1},{ age:1,capacity:4,empty:1},{ age:2,capacity:4,empty:1},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'95',name:'ドリームキッズすがも保育園',position: {lat:35.7338272,lng:139.7364358},address:'東京都豊島区巣鴨3-22-11ベリエステ巣鴨1階',tel:'03-5980-7651',company:'株式会社DK（埼玉県所沢市大字山口1000番地の41）',established:'2015年4月1日',url:'http://www.dk-group.jp/#!dksugamo/ualrf',hour:{openTime:715,closeTime:1915},capacity:12,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:2,empty:0},{ age:1,capacity:5,empty:0},{ age:2,capacity:5,empty:0},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'96',name:'おうち保育園すがも',position: {lat:35.7414536,lng:139.7317996},address:'東京都豊島区巣鴨5-23-8ソシエ巣鴨1階',tel:'03-5972-1224',company:'特定非営利活動法人フローレンス（東京都千代田区神田神保町1-14-1KDX神保町ビル4階）',established:'2016年4月1日',url:'http://mirai.florence.or.jp/ouchi/sugamo/',hour:{openTime:715,closeTime:1915},capacity:12,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:2,empty:0},{ age:1,capacity:6,empty:0},{ age:2,capacity:4,empty:0},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'97',name:'おうち保育園おおつか',position: {lat:35.7312017,lng:139.7268587},address:'東京都豊島区南大塚3-32-1大塚S＆Sビル1階 101号',tel:'03-6912-9960',company:'特定非営利活動法人フローレンス（東京都千代田区神田神保町1-14-1KDX神保町ビル4階）',established:'2015年4月1日',url:'http://mirai.florence.or.jp/ouchi/otsuka/',hour:{openTime:715,closeTime:1915},capacity:12,age:{ageFrom:1, ageTo:2},availability:[{ age:0,capacity:0,empty:0},{ age:1,capacity:6,empty:3},{ age:2,capacity:6,empty:0},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'98',name:'南大塚早樹保育園',position: {lat:35.7292634,lng:139.7280117},address:'東京都豊島区南大塚3-41-10上の台レジデンス1階',tel:'03-5927-9006',company:'株式会社日本チャイルドサポート（東京都東大和市向原6-1201-119）',established:'2014年12月1日',url:'http://minamiohtsuka.saki-hoikuen.jp/',hour:{openTime:715,closeTime:1915},capacity:16,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:3,empty:1},{ age:1,capacity:7,empty:6},{ age:2,capacity:6,empty:2},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'99',name:'ドリームキッズかみいけ保育園',position: {lat:35.7355334,lng:139.7162097},address:'東京都豊島区上池袋2-9-8野本ビル1階',tel:'03-6903-4674',company:'有限会社COCO（東京都小平市花小金井南町3-30-12）',established:'2015年4月1日',url:'http://coco-dreamkids.jp',hour:{openTime:715,closeTime:1915},capacity:12,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:4,empty:2},{ age:1,capacity:5,empty:1},{ age:2,capacity:3,empty:1},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'100',name:'東池袋早樹保育園',position: {lat:35.7314527,lng:139.7253104},address:'東京都豊島区東池袋2-13-7東急ドエル・アルス大塚台1階',tel:'03-6907-3547',company:'株式会社日本チャイルドサポート（東京都東大和市向原6-1201-119）',established:'2013年11月1日',url:'http://higashiikebukuro.saki-hoikuen.jp/',hour:{openTime:715,closeTime:1915},capacity:12,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:2,empty:1},{ age:1,capacity:5,empty:3},{ age:2,capacity:5,empty:0},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'102',name:'ドリームキッズにし池袋保育園',position: {lat:35.7326306,lng:139.7018236},address:'東京都豊島区西池袋5-17-11　ルート西池袋ビル1階',tel:'03-6907-3998',company:'有限会社COCO（東京都小平市花小金井南町3-30-12）',established:'2012年12月1日',url:'https://coco-dreamkids.jp',hour:{openTime:715,closeTime:1915},capacity:19,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:5,empty:2},{ age:1,capacity:8,empty:0},{ age:2,capacity:6,empty:0},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'103',name:'やまのみ池袋保育園',position: {lat:35.7326716,lng:139.6999524},address:'東京都豊島区西池袋5-26-16',tel:'電話: 該当なし',company:'社会福祉法人優和会（福岡県福岡市東区唐原5丁目10番22号）',established:'2015年9月1日',url:'http://www.yamanomi-hoiku.com/',hour:{openTime:715,closeTime:1915},capacity:18,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:2,empty:0},{ age:1,capacity:8,empty:0},{ age:2,capacity:8,empty:0},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'104',name:'イルカ保育園',position: {lat:35.732436,lng:139.706385},address:'東京都豊島区池袋2-15-2武蔵屋第二ビル1階',tel:'電話: 該当なし',company:'聖華株式会社（東京都豊島区池袋4-1-7 アポロ池袋ビル2階）',established:'2015年11月1日',url:'http://www.iruka-seika.com/hoikuen/index.html',hour:{openTime:715,closeTime:1915},capacity:12,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:3,empty:0},{ age:1,capacity:5,empty:1},{ age:2,capacity:4,empty:0},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'105',name:'ソラーナ目白',position: {lat:35.7254453,lng:139.6966057},address:'東京都豊島区目白5-25-6',tel:'03-3565-6270',company:'社会福祉法人フィロス（愛知県名古屋市守山区大字吉根字太鼓ヶ根3231番29）',established:'2016年4月1日',url:'http://www.mejiro-solana.philos2011.com/',hour:{openTime:715,closeTime:1915},capacity:16,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:4,empty:0},{ age:1,capacity:7,empty:0},{ age:2,capacity:5,empty:1},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'107',name:'森のなかま保育園 東長崎ルーム',position: {lat:35.7269258,lng:139.6836597},address:'東京都豊島区南長崎4-24-4',tel:'03-5988-4503',company:'シンリツ株式会社（〒336-0031埼玉県さいたま市南区鹿手袋4-31-17）',established:'2015年7月1日',url:'http://morinonakama.jp/mori_hns',hour:{openTime:715,closeTime:1915},capacity:18,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:6,empty:2},{ age:1,capacity:6,empty:0},{ age:2,capacity:6,empty:1},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'108',name:'目白らるスマート保育所',position: {lat:35.7147844,lng:139.7094552},address:'東京都豊島区高田3-20-1斉藤ビル1階',tel:'03-6907-1791',company:'株式会社日本デイケアセンター（東京都千代田区神田猿楽町2-2-3NSビル4F）',established:'2013年8月29日',url:'https://ndc-lull.com/nursery-school-list/mejiro/',hour:{openTime:715,closeTime:1915},capacity:12,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:2,empty:0},{ age:1,capacity:5,empty:0},{ age:2,capacity:5,empty:1},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'109',name:'北大塚すくすくルーム',position: {lat:35.7343691,lng:139.7245495},address:'東京都豊島区北大塚3-18-19',tel:'03-3910-8533',company:'北大塚すくすくルーム（東京都豊島区北大塚3-18-19）',established:'2008年10月1日',url:'http://kitaootukasukusukuroom.com/',hour:{openTime:800,closeTime:1800},capacity:9,age:{ageFrom:0, ageTo:2},availability:[{ age:0,capacity:2,empty:0},{ age:1,capacity:4,empty:1},{ age:2,capacity:3,empty:1},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'112',name:'長崎すくすくナーサリー',position: {lat:35.7318851,lng:139.6833603},address:'東京都豊島区長崎5-9-15ベルメゾン長崎105号',tel:'03-3974-3335',company:'長崎すくすくナーサリー合同会社（東京都豊島区長崎5-9-15ベルメゾン長崎105号）',established:'2012年4月1日',url:'http://www.nagasaki-sukusuku-nursery.com/',hour:{openTime:830,closeTime:1800},capacity:9,age:{ageFrom:1, ageTo:2},availability:[{ age:0,capacity:0,empty:0},{ age:1,capacity:7,empty:1},{ age:2,capacity:2,empty:0},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'113',name:'高田チャイルドルーム',position: {lat:35.7142532,lng:139.7072826},address:'東京都豊島区高田3-7-13１階',tel:'03-6903-1546',company:'高田チャイルドルーム株式会社（東京都豊島区高田3-7-13SYビル101号）',established:'2011年1月1日',url:'https://www.facebook.com/TAKADAchildroom',hour:{openTime:800,closeTime:1800},capacity:5,age:{ageFrom:2, ageTo:2},availability:[{ age:0,capacity:0,empty:0},{ age:1,capacity:0,empty:0},{ age:2,capacity:5,empty:0},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'114',name:'要町ひよこ保育室',position: {lat:35.7325476,lng:139.6974983},address:'東京都豊島区千早1-22-11　1階',tel:'03-5926-4036',company:'要町ひよこ保育室（東京都豊島区千早1-22-11 1階）',established:'2010年11月1日',url:'https://www.kanamechohiyokohoikushitu.com/',hour:{openTime:800,closeTime:1815},capacity:3,age:{ageFrom:1, ageTo:1},availability:[{ age:0,capacity:0,empty:0},{ age:1,capacity:3,empty:0},{ age:2,capacity:0,empty:0},{ age:3,capacity:0,empty:0},{ age:5,capacity:0,empty:0}],category:2,},
  {id:'115',name:'要町幼稚園',position: {lat:35.7369,lng:139.697754},address:'東京都豊島区要町1-43−15',tel:'03-3957-6450',company:'要町幼稚園',established:'該当なし',url:'https://www.ans.co.jp/k/kaname-kinder/',hour:{openTime:800,closeTime:1800},capacity:10,age:{ageFrom:3, ageTo:5},availability:[{ age:0,capacity:0,empty:0},{ age:1,capacity:0,empty:0},{ age:2,capacity:0,empty:0},{ age:3,capacity:3,empty:0},{ age:5,capacity:7,empty:0}],category:2,},
]

export default Nurseries;